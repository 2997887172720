<template>
    <div class="plan-content">
        <div class="plan-cards-wrapper">
            <div class="card-header">
                <h5>Retiree Profile</h5>
                <div class="card-control plus">
                    <i class="plus iatse-icon-plus"></i>
                    <span class="line"></span>
                </div>
            </div>

            <!-- General Plan Detail Cards -->
            <div class="plan-general-details card card-toggle">
                <div class="plan-general-detail-container card" v-if="dataLoaded">
                    <div class="info-card__icon">
                        <i class="iatse-icon-user-shield"></i>
                    </div>
                    <div class="plan-general-detail">
                        <h5> Coverage </h5>
                        <h4></h4>
                    </div>
                </div>
                <div class="plan-general-detail-container card">
                    <div class="info-card__icon">
                        <i class="iatse-icon-calendar-payment-loan"></i>
                    </div>
                    <div class="plan-general-detail">
                        <h5> Coverage Period </h5>
                        <h4></h4>
                    </div>
                </div>
                <div class="plan-general-detail-container card">
                    <div class="info-card__icon">
                        <i class="iatse-icon-donate"></i>
                    </div>
                    <div class="plan-general-detail">
                        <h5> MRP Balance </h5>
                        <h4></h4>
                    </div>
                </div>
                <div class="plan-general-detail-container card">
                    <div class="info-card__icon">
                        <i class="iatse-icon-money-check-edit"></i>
                    </div>
                    <div class="plan-general-detail">
                        <h5> Administrative Fee Met </h5>
                        <h4></h4>
                    </div>
                </div>
                <div class="plan-general-detail-container card">
                    <div class="info-card__icon">
                        <i class="iatse-icon-legal"></i>
                    </div>
                    <div class="plan-general-detail">
                        <h5> Enrollment Form Sent </h5>
                        <h4></h4>
                    </div>
                </div>
            </div>

            <a class="btn btn-primary" @click.prevent="openModal('hw-retiree-payments-history', 'Payments History', 'plan-retiree')">Payments History</a>

            <!-- Account Actions -->
            <div class="plan-summary__footer row w-100">
                <div class="col-12 col-md-4">
                    <h4>Other Coverages</h4>
                    <div class="account-actions">
                        <!-- Other Coverages -->
                        <div class="action-buttons">

                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-8">
                    <h4>Account Actions</h4>
                    <div class="action-buttons mt-10">
                        <a href="" class="btn btn-secondary plan-modal"
                            @click.prevent="openModal('beneficiaries', 'My Beneficiaries', 'plan-tables-striped beneficiary', null, null, null
                            )">
                            Beneficiaries
                        </a>
                        <a 
                            href="#"
                            class="btn btn-secondary btn-large plan-modal"
                            @click.prevent="openModal('claims', 'Claims', 'plan-upload plan-card-info plan-c-mrp-claims')">
                            Claims
                        </a>
                        <a href="" class="btn btn-secondary btn-large plan-modal"
                            @click.prevent="openModal('dependents', 'My Dependents', null)">My Dependents
                        </a>
                    </div>
                </div>
            </div> <!-- end actions -->
        </div>
    </div>
</template>

<script lang="ts">
import { ref, onMounted } from 'vue';
import axios from 'axios';
import { useModalStore } from '@/stores/modal';
import { useCurrencyFormatter } from "@/composable/useCurrencyFormatter";
import { PlanRetireeSummary } from '@/interfaces/health';
import { useCoverageStore } from '@/stores/coverage';

export default {
    setup() {
        const dataLoaded = ref(false);
        const openModal = useModalStore().openModal;
        const { formatCurrency } = useCurrencyFormatter();
        const coverageStore = useCoverageStore();
        const planSummary = ref<PlanRetireeSummary> ({
            RetireeBenefits: []
        });

        // Fetch data from the API, sending a flag if periods are already fetched
        const fetchData = async() => {
            axios
                .get('/api/participant/health-welfare/plan-retiree-summary',)
                .then((response) => {
                    planSummary.value = response.data;
                    dataLoaded.value = true;

                    // Check if coverage data is present in the store
                    // if (coverageStore.coverage) {
                    //     planSummary.value.Coverage = coverageStore.coverage;
                    // }
                })
                .catch((error) => {
                    console.error('Error fetching data:', error);
                });
        };

        onMounted(() => {
            fetchData();
        });

        return {
            openModal, planSummary
        };
    },
};
</script>