<template>
    <div class="pdl-10 pdt-30 pdr-10">
        <LoaderComponent v-if="loading" />
        <div v-else class="d-flex flex-column gap-25">
            <div class="d-flex gap-25 flex-md-row flex-column">
                <div class="w-35 d-flex flex-column gap-25 w-sm-100">
                    <ProfilePanel :data="profileData" type="Local" />
                    <MembersPanel :cappReport="cappReport" />
                </div>
                <div class="w-70 w-sm-100">
                    <RecentAnnouncements :announcements="announcements"/>
                </div>
            </div>
            <ReportsPanel :contributions="contributionsPreview" />
            <ResourcesPanel :resources="resources" />
        </div>
    </div>
</template>

<script lang="ts">
import { Ref, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useMembersStore } from '@/stores/members'
import { useContributionsStore } from '@/stores/contributions';
import { ProfileData } from '@interfaces/interfaces';
import { ContributionPreview, FundMaterials, Announcements } from '@interfaces/local';
import ReportsPanel from '@components/local/dashboard/ReportsPanel.vue';
import MembersPanel from '@/components/local/dashboard/MembersPanel.vue';
import ResourcesPanel from '@components/local/dashboard/ResourcesPanel.vue';
import ProfilePanel from '@components/user/dashboard/ProfilePanel.vue';
import RecentAnnouncements from '@components/user/dashboard/RecentAnnouncements.vue';
import axios from 'axios';

export default {
    components: {
        ProfilePanel,
        MembersPanel,
        RecentAnnouncements,
        ReportsPanel,
        ResourcesPanel
    },

    setup() {
        const profileData: Ref<ProfileData | null> = ref(null);
        const contributionsPreview: Ref<ContributionPreview | null> = ref(null);
        const resources: Ref<FundMaterials | null> = ref(null);
        const cappReport: Ref<number | null> = ref(null);
        const announcements: Ref<Announcements | null> = ref(null);
        const loading = ref(true);
        const route = useRoute();
        const success: Ref<string | null> = ref(null);

        const fetchData = async () => {
            loading.value = true;
            success.value = route.params.successMessage as string;
            useMembersStore().setList([]);
            useContributionsStore().resetData();

            if (success.value) {
                setTimeout(() => success.value = null, 3500);
            }

            await axios
                .get('api/local/dashboard')
                .then(response => {
                    profileData.value = response.data.Profile;
                    contributionsPreview.value = response.data.ContributionsPreview;
                    cappReport.value = response.data.CappReport;
                    resources.value = response.data.Resources;
                    announcements.value = response.data.Announcements;
                })
                .catch(error => {
                    console.error('There was an error!', error);
                })

            loading.value = false;
        };

        onMounted(async () => await fetchData());

        return {
            loading,
            success,
            profileData,
            resources,
            announcements,
            cappReport,
            contributionsPreview
        };
    }
}
</script>