<template>
  <div :class="['textarea', customClass]">
    <label :for="label" class="body-text">
      {{ label }} <small v-if="message" class="fst-italic"> ({{ message }})</small>
      <span v-if="isRequired" class="text-danger"> * </span>
      <span v-if="optional" class="optional"> (Optional)</span>
    </label>
    <textarea :placeholder="placeholder" :rows="rows" :cols="cols" :value="modelValue" :maxlength="max"
      @input="$emit('update:modelValue', $event.target?.value)" :readonly="readonly" class="links"></textarea>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  props: {
    label: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: false,
    },
    modelValue: {
      type: String || Number,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    customClass: {
      type: String,
      default: '',
    },
    optional: {
      type: Boolean,
      default: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Number,
      default: 6,
    },
    cols: {
      type: Number,
      default: 30,
    },
    max: {
      type: Number,
      default: 400,
    },
  },
});
</script>