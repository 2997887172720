<template>
    <div class="modal-body">
      <div class="d-flex justify-center flex-column w-100">
        <p>
          We have sent you a verification code to your please check and add the code below in the boxes.
        </p>
        <div class="mt-10">
            <VerificationCodeInput v-model="verificationCode" />
        </div>
        <a href="" class="btn btn-primary w-100 mt-20" @click.prevent="submitCode">Enter Code</a>
        <div class="text-center mt-10">
            <a href="" class="links">Resend Code ({{ timer }} seconds remaining)</a>
        </div>
      </div>
    </div>
  </template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import { useModalStore } from '@/stores/modal';
import axios from 'axios';
import VerificationCodeInput from '@components/form/VerificationCodeField.vue';

export default defineComponent({
components: {
    VerificationCodeInput,
},

setup() {
    const modalStore = useModalStore();
    const method = modalStore.currentModal?.queryParams?.method;
    const timer = ref(0);
    const timerInterval = ref<number | null>(null);
    const expirationTime = ref(120);

    const verificationCode = ref('');

    const closeModal = () => {
        console.log('test');
    };

    const submitCode = async () => {
        const response = await axios.post(
            '/api/authorization/change-method/submit-code', {
                verification_code: verificationCode.value,
                method: method
            }
        );
        alert(response.data);
    };

    const generateCode = async () => {
        const response = await axios.post(
            'api/authorization/change-method/generate-code', {
                method: method
            }
        );

        if (response.data.success) {
            if (response.data.expirationTime) {
                expirationTime.value = response.data.expirationTime;
            }
            else {
                expirationTime.value = 120;
            }

            startTimer();
        }
    };

    const startTimer = () => {
        timer.value = expirationTime.value;
        if (timerInterval.value) {
            clearInterval(timerInterval.value);
        }
        timerInterval.value = setInterval(() => {
            if (timer.value > 0) {
              timer.value--;
            }
            else {
              clearInterval(timerInterval.value!);
              timerInterval.value = null;
            }
          }, 1000);
    };

    onMounted(() => {
        generateCode();
    });

    return {
        timer,
        expirationTime,
        method,
        closeModal,
        verificationCode,
        submitCode,
    };
},
});
</script>
