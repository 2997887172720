<template>
  <!-- <div class="bordered-card bordered-card-beneficiary" :class="{ 'has-changes': beneficiary?.Additional?.PendingBene }"> -->
  <div class="bordered-card bordered-card-beneficiary"
    :class="{ 'has-changes': beneficiary?.Additional?.PendingDeletion }">
    <div class="bordered-card-header">
      <h2 class="d-flex gap-2">{{ beneficiary?.Name }}
      </h2>

      <div class="action-buttons">
        <i class="iatse-icon-edit plan-modal"
          @click="!beneficiary?.Additional?.PendingDeletion && handleEditBene(beneficiary)"></i>
        <i class="iatse-icon-trash plan-modal"
          @click="!beneficiary?.Additional?.PendingDeletion && handleDelete(beneficiary)"></i>
      </div>
    </div>
    <!-- Beneficiary Card -->
    <div class="card-wrapper">
      <BeneficiaryCard mode="single" :title="beneficiary.Name" :data="beneficiary.General" :beneficiary="beneficiary"
        :actionButtons="true" customClass="single-card" />
    </div>

    <!-- Table Card -->
    <div class="bordered-card table-card">
      <div class="bordered-card-header">
        <h4>
          Allocations for Beneficiaries
        </h4>
        <p class="text-underline plan-modal"
          @click="handleEditAssignments">Edit Assignments</p>
      </div>
      <div class="plan-content" v-if="beneficiary.Allocations">
        <DataTable :table="beneficiary.Allocations" :classes="{
          'striped small': true
        }" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue';
import { useModalStore } from '@/stores/modal';
import { useBeneficiaryStore } from '@/stores/beneficiary';
import { Beneficiary } from "@/interfaces/beneficiary";
import BeneficiaryCard from '@/components/cards/BeneficiaryCard.vue';
import DataTable from '@/components/form/DataTable.vue';

export default defineComponent({
  components: {
    BeneficiaryCard,
    DataTable
  },
  props: {
    beneficiary: {
      type: Object as PropType<Beneficiary>,
      required: true
    },
  },
  setup(props) {
    const beneStore = useBeneficiaryStore();
    const openModal = useModalStore().openModal;

    const handleEditAssignments = () => {
      beneStore.isAddAction = false;
      beneStore.isEditAction = false;
      beneStore.isDeleteAction = false;
      beneStore.reloadAssignments();
      openModal('assign-fund', 'Assign Fund To Beneficiary', 'plan-upload');
    }

    const handleEditBene = (bene: Beneficiary) => {
      beneStore.isAddAction = false;
      beneStore.isEditAction = true;
      beneStore.getEditBene(bene)
      openModal('add-beneficiary', 'Edit Beneficiary', 'plan-form plan-upload edit-beneficiary', null);
    }

    const deleteBeneReq = async (beneficiary: Beneficiary) => {
      beneStore.isDeleteAction = true;
      beneStore.isAddAction = false;
      beneStore.isEditAction = false;
      beneStore.deletedBeneficiary = beneficiary?.BeneficiaryID ?? null;

      if (beneStore.beneficiaries.length > 1 && beneficiary?.Allocations?.rows?.length) {
        openModal('success-modal', null, 'modal-success', null, beneStore.getDeleteModalProps);
      }
      else {
        await beneStore.handleSubmitNewBenes(true);
        openModal('success-modal', null, 'modal-success', null, beneStore.getSuccessDeleteModalProps);
        await beneStore.fetchBeneficiaries();
      }
    }

    const handleDelete = (beneficiary: Beneficiary) => {
      const modalProps = {
        title: "Delete Beneficiary",
        content: {
          type: "text",
          value: 'Are you sure you want to delete beneficiary: ' + beneficiary?.Name + '?',
          icon: "iatse-icon-user-verified",
        },
        confirmButtons: ["Cancel", "Confirm"],
        onConfirm: () => {
          deleteBeneReq(beneficiary);
        },
      }

      openModal('success-modal', null, 'modal-success', null, modalProps);
    }

    return {
      openModal,
      handleEditBene,
      handleEditAssignments,
      handleDelete
    }
  }
});
</script>