<template>
    <LoaderComponent v-if="loading" />
    <div v-else class="plan-content">
        <!-- General Plan Detail Cards -->
        <div class="plan-details">
            <ProfileInfo title="Total Credits" type="pension" :items="firstCard" :has-divider="false"
                :use-container="true" />
            <ProfileInfo title="Key Dates" type="pension" :items="secondCard" :has-divider="false"
                :use-container="true" />
        </div>

        <div class="card">
            <h4 class="pdb-10"> Payments and Contributions </h4>
            <table class="striped small equal-widths toggle no-underline">
                <thead>
                    <tr>
                        <th v-for="(header, key) in pensionStore.history.headers" :key="key">{{ header.title }}</th>
                    </tr>
                </thead>

                <tbody v-if="pensionStore.history.rows">
                    <template v-for="([year, data], mainKey) in Object.entries(pensionStore.history.rows).reverse()"
                        :key="mainKey">
                        <tr class="parent-row">
                            <td class="vertical-top">
                                <p v-if="data.Children" class="cell toggle-cell" @click="toggleCell(mainKey)">
                                    <span class="view-details"> {{ toggleStates[mainKey] ? '-' : '+' }}</span>
                                    <span class="text-underline">{{ year }}</span>
                                </p>
                                <p v-else class="cell">
                                    <span v-if="pensionStore.hasChildren" class="view-details"></span>
                                    <span class="text-underline">{{ year }}</span>
                                </p>
                            </td>
                            <td colspan="10">
                                <div class="table-row_wrapper d-flex flex-column gap-4">
                                    <div v-if="!toggleStates[mainKey]" class="d-flex justify-content-between gap-4">
                                        <div v-for="(item, key) in data.Parent" :key="key" class="cell d-flex gap-2">
                                            <p class="cell-header hide-desktop"> {{ key }} </p>
                                            <p class="cell-text">{{ item }}</p>
                                        </div>
                                    </div>
                                    <transition name="slide-fade">
                                        <div v-if="toggleStates[mainKey] && data.Children" class="subitems-columns">
                                            <template v-for="(child, index) in  Object.values(data.Children) "
                                                :key="index">
                                                <div class="d-flex subitems-columns_wrapper justify-content-between">
                                                    <div class="d-flex cell flex-column gap-2"
                                                        v-for="(childValue, childKey) in child" :key="childKey">
                                                        <p class="cell-header hide-desktop">{{ childKey }}</p>
                                                        <p class="cell-text">{{ childValue }}</p>
                                                    </div>
                                                </div>
                                                <div v-if="index < data.Children.length - 1" class="divider-line m-10">
                                                </div>
                                            </template>
                                            <div class="divider-line black mt-10"></div>
                                            <div class="divider-line black mb-10"></div>

                                            <div
                                                class="d-flex justify-content-between gap-4 subitems-total text-dark fw-bold">
                                                <div v-for="(item, key) in data.Parent" :key="key"
                                                    class="cell d-flex gap-2">
                                                    <p class="cell-header hide-desktop"> {{ key }} </p>
                                                    <p class="cell-text">{{ item }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </transition>
                                </div>
                            </td>
                        </tr>
                    </template>
                </tbody>
                <tbody v-else>
                    <tr class="text-center">
                        <td colspan="11"> No data available</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <!-- Related Links -->
        <div class="account-actions">
            <h4> Related Links </h4>
            <div class="action-buttons">
                <button class="btn btn-secondary plan-modal" @click="openModal('beneficiaries',
                'My Beneficiaries',
                'plan-tables-striped beneficiary',
                null,
                null,
                null,
                [{ name: 'Add Beneficiary', type: 'primary', action: 'add-beneficiary', classes: 'plan-form plan-upload' }]
            )">
                    Beneficiaries
                </button>
                <button class="btn btn-secondary plan-modal"
                    @click.prevent="openModal('pension-application', 'Pension Application', 'plan-form', 'application')">
                    Pension Application
                </button>
                <button class="btn btn-secondary plan-modal"
                    @click.prevent="openModal('pension-application', 'Estimate Request', 'plan-form', 'estimate')">
                    Estimate Request
                </button>
                <a href="" class="btn btn-secondary"> Forms & Documents </a>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, reactive, computed } from 'vue';
import { useModalStore } from '@/stores/modal';
import { usePensionStore } from '@/stores/pension';
import { useFormattedDate } from '@/composable/useFormattedDate';
import { PensionStatus } from '@/interfaces/pension';

import ProfileInfo from '@/components/cards/ProfileInfo.vue';

export default defineComponent({
    components: {
        ProfileInfo,
    },
    setup() {
        const pensionStore = usePensionStore();
        const loading = ref(true);
        const { formatDate } = useFormattedDate();
        const toggleStates = ref<Record<number, boolean>>({});

        const firstCard = computed(() => {
            return [
                { label: 'Vesting Credits', value: pensionStore.data?.totalVestingCredits },
                { label: 'Past Service', value: pensionStore.data?.totalPastServiceCredits },
                { label: 'Future Service', value: pensionStore.data?.totalFutureServiceCredits },
                { label: 'Pension Credits', value: pensionStore.data?.totalPensionCredits },
            ]
        })

        const secondCard = computed(() => {
            return [
                { label: 'Year Vested ', value: pensionStore.data?.vestingYear },
                { label: 'Participation Date', value: pensionStore.data?.participationDate ? formatDate(pensionStore.data?.participationDate) : '' },
            ]
        });

        const fetchData = async () => {
            if (!pensionStore.data || !pensionStore.history) {
                await pensionStore.fetchPensionCredits();
            }

            if (pensionStore.history.rows) {
                Object.values(pensionStore.history.rows).forEach((_, index) => {
                    toggleStates.value[index] = false;
                });
            }

            loading.value = false;
        }


        const isNumeric = (value: any) => {
            return !isNaN(value - parseFloat(value));
        }

        const toggleCell = (index: number) => {
            Object.keys(toggleStates.value).forEach(key => {
                console.log(toggleStates.value[Number(key)], '@toggle');
                toggleStates.value[Number(key)] = Number(key) === index ? !toggleStates.value[Number(key)] : false;
            });
        }

        onMounted(async () => {
            await fetchData();
        });

        return {
            loading,
            firstCard,
            secondCard,
            pensionStore,
            toggleStates,
            isNumeric,
            toggleCell,
            openModal: useModalStore().openModal,
        };
    }
});
</script>