<template>
    <div class="bordered-card bordered-card_secondary gap-20">
        <h4 class="fw-bold">Forms & Documents</h4>
        <template v-for="(resourceGroup, mainKey) in resources" :key="mainKey">
            <div class="primary-group d-flex justify-content-between align-items-center w-100 plan-modal"
                @click="toggleGroup(mainKey)">
                <h5 class="fw-bold"> {{ mainKey }} </h5>
                <!-- Toggle secondary group items on click -->
                <button @click="toggleGroup(mainKey)" class="toggle-btn">
                    <span v-if="!showGroup[mainKey]" class="fs-xl lh-1">+</span>
                    <span v-else class="fs-xl lh-1">-</span>
                </button>
            </div>
            <transition name="slide-fade">
                <div v-if="showGroup[mainKey]"
                    class="secondary-group d-flex justify-content-between flex-md-row flex-column gap-50 w-100">
                    <div v-for="(documents, secondaryKey) in resourceGroup" :key="secondaryKey"
                        class="d-flex flex-column gap-20">
                        <h5 class="fw-semibold"> {{ secondaryKey }} </h5>

                        <div v-for="(documentLink, documentName) in documents" :key="documentName"
                            class="document-item gap-10">
                            <i class="iatse-icon-pdf d-flex"><span class="path1"> </span> <span class="path2">
                                </span></i>
                            <span>{{ documentName }} </span>
                        </div>
                    </div>
                </div>
            </transition>
        </template>
    </div>
</template>

<script lang="ts">
import { PropType, ref, defineComponent } from 'vue';
import { FundMaterials } from '@interfaces/local';

export default defineComponent({
    props: {
        resources: [] as PropType<FundMaterials>
    },

    setup() {
        const showGroup = ref<Record<string, boolean>>({});

        // Toggle the visibility of secondary items for each group
        const toggleGroup = (mainKey: string) => {
            showGroup.value[mainKey] = !showGroup.value[mainKey];
        };

        return {
            showGroup,
            toggleGroup,
        }
    }
})
</script>