<template>
  <div class="pdt-30">
    <div class="d-flex justify-content-between align-items-center pdb-20">
      <h2 class="">Form Submissions</h2>
      <button class="btn btn-primary" @click="handleExport">
        {{ loadingExport ? 'Exporting...' : 'Export To CSV' }}
      </button>
    </div>
    <div v-show="success" class="alert alert-success">{{ success }}</div>
    <LoaderComponent v-if="loading" />
    <form v-else class="bordered-card bordered-card_secondary" @submit.prevent>
      <div class="filters d-flex gap-4 flex-wrap flex-md-nowrap justify-content-between w-100">
        <div class="d-flex gap-4 col-md-8 col-12 w-sm-100">
          <SelectComponent v-model:selectedOption="filters.FormType" :options="formTypes" :single-arrow="true"
            customClass="fs-xs" />
          <SelectComponent v-model:selectedOption="filters.Status" :options="statuses" :single-arrow="true"
            customClass="fs-xs" />
        </div>
        <TextInput type="text" name="mixed" placeholder="Search by Id, and Name..." :max=30 v-model:modelValue="filters.Participant"
          customClass="search justify-content-start" />
      </div>
      <table class="small striped toggle no-underline">
        <thead class="large">
          <tr>
            <th colspan="7">
              <div class="pdl-10 pdr-10 d-flex justify-content-between w-100">
                <div class="col-md-1"> Participant </div>
                <div class="col-md-2"> Web Submission Date </div>
                <div class="col-md-2"> Form Type </div>
                <div class="col-md-2"> Person Name </div>
                <div class="col-md-1"> Person D.O.B. </div>
                <div class="col-md-3 pdl-20"> Notes </div>
                <div class="col-md-1"> Status </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody v-if="submissions.length > 0">
          <template v-for="submission in  submissions " :key="submission.SubmissionID">
            <tr :class="['large', { 'closed': submission.Status === 'Closed' }]">
              <td colspan="7">
                <div class="d-flex flex-column w-100">
                  <div class="d-flex justify-content-between align-items-center flex-wrap flex-md-nowrap w-100 sm-gap-20">
                    <div class="cell col-md-1 col-sm-5">{{ submission.PersonID }}</div>
                    <div class="cell col-md-2 col-sm-5">{{ submission.WebSubmissionDate }}</div>
                    <div class="cell col-md-2 col-sm-5">{{ submission.FormType }}</div>
                    <div class="cell col-md-2 col-sm-5">{{ submission.PersonName }}</div>
                    <div class="cell col-md-1 col-sm-5">{{ submission.PersonDateofBirth }}</div>
                    <div @click="toggleNotes(submission.SubmissionID)"
                      class="cell col-md-3 col-sm-5 pdl-20 pdr-25 sm-pdr-0 sm-pdl-0 d-flex justify-content-between align-items-center">
                      <span>{{ getTruncatedNotes(submission?.Notes) }} </span>
                      <template v-if="submission?.Notes.length > 30">
                        <i class="plan-modal iatse-icon-minus" v-if="isExpanded(submission.SubmissionID)"> </i>
                        <i class="plan-modal iatse-icon-table-plus" v-if="!isExpanded(submission.SubmissionID)"></i>
                      </template>
                    </div>
                    <div class="cell col-md-1 col-sm-5">
                      <small class="label label_small w-fit"
                        :class="{ 'label_danger_secondary': submission.Status == 'Open', 'label_blue': submission.Status == 'Closed' }">
                        <span class="bullet"></span>
                        {{ submission.Status }}
                      </small>
                    </div>
                  </div>
                  <transition name="slide-fade">
                    <div v-if="isExpanded(submission.SubmissionID) && submission?.Notes.length > 30"
                      class="text-center pdt-20 pdb-10">
                      <p v-html="submission?.Notes"></p>
                    </div>
                  </transition>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
        <tbody v-else>
          <tr class="text-center py-5">
            <td colspan="8"> No submissions found </td>
          </tr>
        </tbody>
      </table>
      <PaginationComponent :paginator="paginator" @fetch-data="fetchPaginationData" />
    </form>
  </div>
</template>

<script lang="ts">
import { ref, onMounted, Ref, reactive, watch } from 'vue';
import { Paginator, Submission, Filters } from '@/interfaces/admin';
import { useOptionsFormatter } from "@utils/optionFormatter";
import { SelectOption } from '@/interfaces/interfaces';
import { saveAs } from 'file-saver';

import PaginationComponent from '@components/utils/PaginationComponent.vue';
import TextInput from '@components/form/TextInput.vue';
import SelectComponent from "@components/form/SelectComponent.vue";
import axios from 'axios';

export default {
  components: {
    PaginationComponent,
    TextInput,
    SelectComponent,
  },

  setup() {
    const submissions: Ref<Submission[]> = ref([]);
    const formTypes: Ref<SelectOption[]> = ref([]);
    const paginator: Paginator = reactive({ per_page: 15, total: 300, last_page: 300, current_page: 1 });
    const loading = ref(true);
    const loadingSearch = ref(false);
    const loadingExport = ref(false);
    const success = ref(null);
    const page: Ref<number> = ref(1);
    const route = "api/administrator/form-submissions";

    const statuses = [
      {
        key: 'All',
        value: 'All'
      },
      {
        key: 1,
        value: 'Closed'
      },
      {
        key: 0,
        value: 'Open'
      },
    ]

    const filters: Filters = reactive({ FormType: formTypes.value[0], Participant: '', Status: statuses[0] });
    const expandedRows = ref<{ [key: number]: boolean }>({});

    const toggleNotes = (index: number) => {
      expandedRows.value[index] = !expandedRows.value[index];
    };

    const isExpanded = (index: number) => {
      return !!expandedRows.value[index];
    };

    const getTruncatedNotes = (notes: string) => {
      const maxLength = 30;
      return notes.length > maxLength ? notes.substring(0, maxLength) + '...' : notes;
    };

    watch(filters, async () => {
      loadingSearch.value = true;
      page.value = 0;
      await fetchData(false);
      loadingSearch.value = false;
    });

    const handleCloseSubmission = async (submissionID: number) => {
      const submission = submissions.value.find((submission: Submission) => submission.SubmissionID === submissionID);

      if (submission) {
        submission.Submitting = true;

        await axios
          .post(`${route}?SubmissionID=${submissionID}`, { _method: "PATCH" })
          .then((response) => {
            success.value = response.data?.success;
          })
          .finally(() => {
            submission.Submitting = false;
            setTimeout(() => success.value = null, 3500);
            fetchData(false);
          });
      }
    }

    // Handle Download CSV File
    const handleExport = async () => {
      loadingExport.value = true;

      try {
        const response = await axios.get(route, {
          params: {
            Filters: filters,
            Page: page.value,
            Export: true,
          },
          responseType: 'blob',
        });

        const blob = new Blob([response.data], {
          type: response.headers['content-type'],
        });

        saveAs(blob, 'submissions.csv');
      } catch (error) {
        console.error('Error downloading file:', error);
      }

      loadingExport.value = false;
    }

    const fetchData = async (isLoading: boolean) => {
      if (isLoading) {
        loading.value = true;
      }

      await axios.get(route, { params: { page: page.value, Filters: filters } })
        .then(response => {
          submissions.value = response.data.Submissions;
          formTypes.value = useOptionsFormatter(response.data.FormTypes);
          Object.assign(paginator, response.data.Paginator);
          page.value = paginator.current_page;

          if (isLoading) {
            filters.FormType = formTypes.value[0];
          }
        })
        .finally(() => {
          if (isLoading)
            loading.value = false;
        });
    };

    const fetchPaginationData = (pageNumber: number) => {
      page.value = pageNumber;
      fetchData(false);
    }

    onMounted(async () => await fetchData(true));

    return {
      filters,
      statuses,
      submissions,
      formTypes,
      paginator,
      success,
      loading,
      loadingSearch,
      loadingExport,
      toggleNotes,
      isExpanded,
      getTruncatedNotes,
      handleCloseSubmission,
      fetchData,
      handleExport,
      fetchPaginationData,
    };
  }

}
</script>
