<template>
    <Alerts :success="success" :errors="errors" />
    <LoaderComponent v-if="loading" />
    <div v-else class="plan-content">
        <!-- Form -->
        <form v-if="person && person.DateofBirth" id="check-destination-form" class="form" method="POST"
            @submit.prevent="submitData">
            <SelectComponent label="Check Type" v-model:selectedOption="requestType" :options="checkTypes"
                :singleArrow="true" :readonly="true" />
            <SelectComponent label="Type" v-model:selectedOption="checkType" :options="paymentMethods"
                :singleArrow="true" />
            <TextInput type="text" label="Participant ID" placeholder="Enter Participant ID" :max=25
                v-model:modelValue="person.PersonID" :readonly="true" />
            <TextInput type="text" label="First Name" placeholder="Enter First Name" :max=12
                v-model:modelValue="person.FirstName" :readonly="true" />
            <TextInput type="text" label="Last Name" placeholder="Enter Last Name" :max=25
                v-model:modelValue="person.LastName" :readonly="true" />
            <DateInput label="DOB" v-model:modelValue="person.DateofBirth" :readonly="true" />

            <div class="headers">
                <h4 v-if="checkType.key === 'check'">Address for Check Destination currently on File </h4>
                <h4 v-else>Bank Account Information</h4>
                <h5 v-if="checkType.key === 'directdeposit'"> Current Banking Information </h5>
            </div>

            <div class="w-full" v-if="address && checkType.key === 'check'">
                <TextInput type="text" label="Address 1" placeholder="Enter Address 1" :max=30
                    v-model:modelValue="address.Street1" :required="true" />
                <TextInput type="text" label="Address 2" placeholder="Enter Address 2" :max=30
                    v-model:modelValue="address.Street2" />
                <TextInput type="text" label="Address 3" placeholder="Enter Address 3" :max=30
                    v-model:modelValue="address.Street3" />
                <TextInput type="text" label="City" placeholder="Enter City" :max=25 v-model:modelValue="address.City"
                    :required="true" />
                <SelectComponent label="Country" v-model:selectedOption="address.Country" :options="countries"
                    :single-arrow="true" />
                <SelectComponent label="State" v-model:selectedOption="address.State" :options="filteredStates"
                    :single-arrow="true" />
                <TextInput type="text" label="ZIP Code" name="number" placeholder="Enter Postal Code" :max=9
                    v-model:modelValue="address.Zip" :required="true" />
            </div>

            <div class="w-full justify-content-start" v-else>
                <TextInput type="text" label="Bank Name" placeholder="Enter Bank Name" :max=30
                    v-model:modelValue="bank.BankClassDescription" :required="true" />
                <TextInput type="text" name="number" label="Routing (ABA)" placeholder="Enter Routing (ABA)" :max=20
                    v-model:modelValue="bank.RoutingCode" :required="true" />
                <SelectComponent label="Account Type" v-model:selectedOption="bank.AcctType" :options="accountTypes"
                    :single-arrow="true" />
                <TextInput type="text" name="number" label="Account Number" placeholder="Enter Account Number" :max=20
                    v-model:modelValue="bank.BankAcctNo" :required="true" />
                <TextInput type="text" name="number" label="Repeat Account Number" placeholder="Repeat Account Number"
                    :max=20 v-model:modelValue="bank.RepeatBankAcctNo" :required="true" />
            </div>

            <Textarea label="Notes" v-model:modelValue="comments" />

            <div class="action-buttons">
                <button @click.prevent="resetModal" class="btn btn-secondary" :disabled="loadingSubmit"> Discard </button>
                <button type="submit" class="btn btn-primary" :disabled="loadingSubmit"> {{ loadingSubmit ? 'Submitting...' : 'Submit' }} </button>
            </div>
        </form>
        <div v-else class="d-flex flex-column align-items-center gap-4">
            <h3>To Continue Please...</h3>
            <h6>Please <a href="profile.personal.edit">update your personal
                    information</a> to include your correct date of birth.</h6>
        </div>
    </div>
</template>

<script lang="ts">
import { ref, toRefs, onMounted, watch, computed, Ref, defineComponent } from 'vue';
import { useRoute } from 'vue-router';
import { usePensionStore } from '@/stores/pension';
import { useVacationStore } from '@/stores/vacation';
import { useProfileStore } from '@/stores/profile';
import { CheckDeposit } from '@/interfaces/health';
import { CountryStates, Person, AddressInfo, Bank, SelectOption, VacationCheckRequestLine } from '@/interfaces/interfaces';

import Alerts from '@/components/utils/AlertsComponent.vue';
import TextInput from '@/components/form/TextInput.vue';
import DateInput from '@/components/form/DateInput.vue';
import Textarea from '@/components/form/TextareaComponent.vue';
import SelectComponent from '@/components/form/SelectComponent.vue';
import useFormSubmit from '@/composable/useFormSubmit';
import axios from 'axios';

export default defineComponent({
    components: {
        Alerts,
        TextInput,
        DateInput,
        Textarea,
        SelectComponent
    },
    props: {
        fund: {
            type: String,
            default: null,
        }
    },
    setup(props, { emit }) {
        const profileStore = useProfileStore();
        const pensionStore = usePensionStore();
        const vacationStore = useVacationStore();
        const statesByCountry: Ref<CountryStates[] | null> = ref(null);
        const person = ref<Person>();
        const accountTypes = ref(null);
        const initialAddress = ref({
            Street1: null,
            Street2: null,
            Street3: null,
            Country: null,
            City: null,
            State: null,
            ZipCode: null,
        })
        const initialBank = ref({
            BankClassDescription: null,
            RoutingCode: null,
            AcctType: null,
            BankAcctNo: null,
            RepeatBankAcctNo: null,
        })
        const address = ref<AddressInfo>({...initialAddress.value});
        const bank = ref<CheckDeposit>({...initialBank.value});

        const checkTypes = [
            { key: 'vacation', value: 'Vacation' },
            { key: 'medicare', value: 'Medicare Reimbursement' },
            { key: 'pension', value: 'Pension' }
        ];

        const paymentMethods = [
            { key: 'directdeposit', value: 'ACH' },
            { key: 'check', value: 'Check Destination' }
        ];

        const checks = ref<VacationCheckRequestLine[]>([]);
        const checkOptions = ref<SelectOption[]>([]);
        const selectedCheck = ref<SelectOption | null>({ key: 0, value: '-Select check' });
        const requestType = ref<SelectOption>(checkTypes[0]);
        const checkType = ref<SelectOption>(paymentMethods[0]);
        const { fund } = toRefs(props);
        const comments = ref('');
        const errors = ref<string[]>([]);
        const success = ref('');
        const info = ref('');
        const loading = ref(true);
        const loadingSubmit = ref(false);
        const updateDepositRoute = 'api/participant/claims-update-deposit';
        const updateAddressRoute = 'api/user/profile/my-address';
        const postRoute = ref(updateDepositRoute);

        // Methods
        const fetchData = async () => {
            try {
                const response = await axios.get(`api/participant/check-replacement?fund=${props.fund}`);
                person.value = response.data.Person;
                address.value = response.data.Address;
                bank.value = response.data.Bank;
                initialAddress.value = JSON.parse(JSON.stringify(response.data.Address));
                initialBank.value = JSON.parse(JSON.stringify(response.data.Bank));
                accountTypes.value = response.data.AccountTypes;
                checkOptions.value = response.data.CheckOptions;

                if (!profileStore.countries || !profileStore.statesByCountry) {
                    await profileStore.fetchCountriesStates();
                }

                loading.value = false;
            }
            catch (error) {
                console.error('Error:', error);
            }
        }

        const updateRequestTypeFromQuery = () => {
            const queryParamValue = fund.value;

            if (typeof queryParamValue === 'string' && ['vacation', 'medicare', 'pension'].includes(queryParamValue)) {
                requestType.value = checkTypes.find(type => type.key === queryParamValue) || checkTypes[0];
            }
        };

        const closeModal = () => {
            emit('close');
        }

        const resetModal = () => {
            address.value = { ...initialAddress.value};
            bank.value = { ...initialBank.value};
            checkType.value = paymentMethods[0];
            comments.value = '';
        }

        // Filter states based on selected country
        const filteredStates = computed(() => {
            const selectedCountry = address.value?.Country?.key ?? '';
            const states = profileStore.statesByCountry;
            const countryStates = states ? (states as CountryStates[]).filter((state) => state.CountryKey == selectedCountry) : [];

            return (countryStates?.map(state => state.States).flat());
        });

        watch(filteredStates, () => {
            if(!address.value.State) {
                address.value.State = { key: 0, value: "Select State" };
            }
        })

        watch(() => fund.value, () => {
            updateRequestTypeFromQuery();
        });

        watch(() => checkType.value, (newType) => {
            postRoute.value = newType?.key === 'directdeposit' ? updateDepositRoute : updateAddressRoute;
        });

        const submitData = async () => {
            let formData = null;

            if (checkType.value.key === 'directdeposit') {
                formData = {
                    PersonID: person.value?.PersonID,
                    FirstName: person.value?.FirstName,
                    LastName: person.value?.LastName,
                    DateofBirth: person.value?.DateofBirth,
                    Bank: { ...bank.value, 'AcctType': bank.value.AcctType?.key  ?? null},
                    Type: requestType.value,
                    Notes: comments.value,
                };

            }
            else {
                formData = {
                    CheckCategory: requestType.value.key,
                    Type: address.value.Type?.key,
                    Street1: address.value.Street1,
                    Street2: address.value.Street2,
                    Street3: address.value.Street3,
                    State: address.value.State,
                    City: address.value.City,
                    Country: address.value.Country,
                    ZipCode: address.value.Zip,
                }
            }

            const route = postRoute.value;
            const { handleSubmit } = useFormSubmit({ formData, route, success, info, errors, loadingSubmit });
            const successSubmit = await handleSubmit();

            if (successSubmit) {
                await profileStore.fetchAddress();
                await pensionStore.getPanelDetails();
                await vacationStore.getPanelDetails();
            }
        }

        onMounted(async () => {
            await fetchData();
            updateRequestTypeFromQuery();
        });

        return {
            countries: profileStore.countries,
            statesByCountry,
            filteredStates,
            person,
            comments,
            address,
            bank,
            checks,
            checkOptions,
            selectedCheck,
            requestType,
            checkTypes,
            paymentMethods,
            accountTypes,
            checkType,
            success,
            errors,
            loading,
            loadingSubmit,
            closeModal,
            submitData,
            resetModal
        }
    }
});
</script>
