<template>
  <AlertsComponent :error="error" />
  <div class="card" :class="{ 'active': isVisible }">
    <div class="d-flex flex-column justify-between gap-2 fw-light w-100">
      <div class="card-header w-100">
        <h2> {{ title }} </h2>
        <!-- <transition name="slide-fade"> -->
        <a href="#" class="btn btn-secondary card-toggle-control" :class="{ 'hidden': isVisible }"
          @click.prevent="toggleCardContent(true)">
          {{ savedAllocationBtn }}
        </a>
        <!-- </transition> -->

        <i class="iatse-icon-arrow-down card-toggle-control" :class="{ hidden: !isVisible }"
          @click="toggleCardContent(false)"></i>
      </div>

      <div v-if="uploadSection && isVisible" class="upload-area">
        <p class="mb-20"> Federal law provides that a spouse, if applicable, is entitled to all retirement (Pension
          and/or Annuity) benefits upon your retirement or death. They will automatically be your beneficiary unless
          the Fund(s) receive a waiver of such entitlement by your spouse. </p>
        <p>If you are married and would like to name someone other than your spouse, you must obtain a waiver from
          your spouse and upload it with your submission. <a class="fw-normal" href="">Click here to access the
            waver.</a> </p>
      </div>
    </div>

    <transition name="slide-fade">
      <div class="card-content card-toggle pt-0" :class="{ 'active': isVisible }" v-if="isVisible">
        <div class="radio-wrapper">
          <div v-if="uploadSection" class="upload-area mt-4">
            <div class="text-wrapper">
              <h4> Upload Document </h4>
              <p>Upload documentation evidencing your beneficiary's identity as well as the spouse consent / waiver of
                primary coverage form here.</p>
            </div>
            <DragDrop customClasses="grey small" text="Upload a scanned copy" @change="handleFileSelection"
              key="drag-drop-bene-waiver" />
          </div>
        </div>
        <RangeSlider label="Assign Fund Percentage" :sliders="sliders" :title="title" :delete-button="true"
          @update="handleAssignments" @edit="() => sliderEdited = true"  />
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, Ref, computed, ErrorCodes } from 'vue';
import { RadioOption, SliderConfig, Allocations } from '@/interfaces/interfaces';
import { useModalStore } from '@/stores/modal';
import { useBeneficiaryStore } from '@/stores/beneficiary';
// import RadioButton from '@/components/form/RadioButton.vue';
import RangeSlider from '@/components/form/RangeSlider.vue';
import DragDrop from '@/components/form/DragDrop.vue';

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true
    },
  },
  components: {
    // RadioButton,
    RangeSlider,
    DragDrop
  },
  setup(props, { emit }) {
    const isVisible = ref(false);
    const loadingUpload = ref(false);
    const error = ref<string>('');
    const beneStore = useBeneficiaryStore();
    const sliders = computed(() => beneStore.getSlidersByFund(props.title));
    const assignments = computed(() => beneStore.assignments);
    const savedAllocations = ref(false);
    const sliderEdited = ref(false);
    const relationChecked = ref(false);
    const uploadSection = ref(false);
    const beneNonSpousePrimary = ref(null);
    const savedAllocationBtn = computed(() => {
      if (isVisible.value && sliderEdited.value) {
        return 'Save Assignment';
      } else if ((!isVisible.value && savedAllocations.value) || (!isVisible.value && !beneStore.isAddAction)) {
        return 'Edit Assignment';
      }

      return 'Assign Fund';
    });

    const levels: RadioOption[] = [
      { label: 'Primary', value: 'Primary' },
      { label: 'Secondary', value: 'Secondary' },
      { label: 'Tertiary', value: 'Tertiary' },
    ];

    const groupLevel = ref(levels[0].label);
    const payloadPercentages: Ref<Record<string, number> | null> = ref(null);

    const toggleCardContent = async (submit?: boolean) => {
      if (!beneStore.isAddAction) {
        emit('show-buttons');
      }

      if (['Pension', 'Annuity'].includes(props.title)) {
        await checkRelationship();
      }

      if (isVisible.value && submit && validatePercentages()) {
        savedAllocations.value = true;

        if (payloadPercentages.value) {
          if (assignments.value) {
            assignments.value[props.title] = {
              Allocations: payloadPercentages.value,
              IsEdited: true,
            }

            beneStore.filterAssignments();
          }

          Object.entries(payloadPercentages.value).forEach(([beneId, assignments]) => {
            const slider = Object.values(sliders.value).find((item: SliderConfig) => item.entityId == beneId);

            if (slider) {
              slider.value = (assignments as Allocations).Percentage as number;
            }
          });
        }
      }

      if (!isVisible.value || (isVisible.value && !submit) || validatePercentages()) {
        isVisible.value = !isVisible.value;
      }
    }

    const handleFileSelection = async (event: Event) => {
      loadingUpload.value = true;
      const formData = new FormData();
      const files = (event.target as HTMLInputElement)?.files || (event as DragEvent)?.dataTransfer?.files;

      if (files) {
        for (let i = 0; i < files.length; i++) {
          formData.append('Files[]', files[i]);
        }

        await beneStore.uploadFiles(formData);
      }

      loadingUpload.value = false;
    };

    // Handle Adding Bene - NOT Spouse
    const checkRelationship = (): Promise<boolean> => {
      return new Promise((resolve) => {

        if (beneStore.newBeneficiaries.length === 1 && !beneStore.beneficiaries.length) {
          const beneficiary = beneStore.newBeneficiaries[0];

          if (Number(beneficiary?.Relationship?.key) !== 181) {
            resolve(true);
            uploadSection.value = true;
            console.log('One beneficiary added and is NOT a spouse:', beneficiary);
          } else {
            uploadSection.value = false;
            resolve(true);
          }
        } else {
          beneStore.beneficiaries.forEach(bene => {
            if (bene.Additional?.RelationOption?.key != 181) {
              uploadSection.value = true;
            }
          })

          beneStore.newBeneficiaries.forEach(bene => {
            if (bene.Relationship?.key != 181) {
              uploadSection.value = true;
            }
          })

          resolve(true);
        }
      });
    };

    const validatePercentages = () => {
      if (payloadPercentages.value) {
        const groupPercentages: Record<string, number> = {};

        // Accumulate percentages per group level
        Object.values(payloadPercentages.value).forEach(allocation => {
          const groupLevel = (allocation as Allocations).GroupLevel
          if (groupLevel) {
            // Initialize the group level in the map if it doesn't exist
            if (!groupPercentages[groupLevel]) {
              groupPercentages[groupLevel] = 0;
            }

            // Add the current allocation percentage to the respective group level
            groupPercentages[groupLevel] += (allocation as Allocations)?.Percentage ?? 0;
          }
        });

        // Check if any group level exceeds 100%
        for (const [groupLevel, totalPercentage] of Object.entries(groupPercentages)) {
          if (totalPercentage !== 100 && totalPercentage !== 0) {
            error.value = `Total percentages must equal 100% for each Level`;
            setTimeout(() => (error.value = ''), 2500);

            return false; // Return false if any group level's total is not 100%
          }
        }
      }

      return true; // Return true if all validations pass
    };

    const handleAssignments = (payload: { type: string, value: Record<string, number> }) => {
      if (payload.type === 'percentages') {
        payloadPercentages.value = payload.value;
      }
    }

    return {
      levels,
      error,
      groupLevel,
      sliders,
      isVisible,
      savedAllocations,
      savedAllocationBtn,
      sliderEdited,
      uploadSection,
      isAddAction: beneStore.isAddAction,
      toggleCardContent,
      handleAssignments,
      handleFileSelection
    };
  }
});
</script>