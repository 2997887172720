<template>
    <AlertsComponent :errors="errors" />
    <div class="pdl-10 pdr-10 mt-30">
        <h2 class="mb-20">Summarized Contract Information</h2>
        <div class="d-flex flex-column gap-4">
            <div class="d-flex flex-column gap-20">
                <div class="d-flex align-items-end gap-20 w-100">
                    <div class="d-flex align-items-end gap-20 w-50">
                        <DateInput label="Work Period" v-model:modelValue="filters.WorkPeriod.StartDate" :isRequired="true" />
                        <DateInput v-model:modelValue="filters.WorkPeriod.EndDate" />
                    </div>
                    <div class="custom-select links w-50">
                        <label class="body-text">  Employer(s): </label>
                        <Multiselect v-model="filters.Employers" :options="data.employers" placeholder="Type to search"
                            :multiple="true" :hide-selected="true" :searchable="true" :close-on-select="true"
                            @select="handleEmployerChange" label="Name" track-by="ID"
                            @search-change="handleEmployersSearch" @remove="handleProductions"
                            :loading="loading.employers">
                        </Multiselect>
                    </div>
                </div>
                <div class="d-flex align-items-end gap-20 w-100">
                    <div class="custom-select links">
                        <label class="body-text"> Production(s) <span v-if="hasEmployers"> for selected
                                Employer(s)</span>: </label>
                        <Multiselect v-model="filters.Productions" :options="data.productions"
                            placeholder="Type to search" :multiple="true" :hide-selected="true" :searchable="true"
                            :close-on-select="true" @select="handleProductionChange" label="Name" track-by="ID"
                            @open="handleProductionsOpen" @search-change="handleProductionsSearch"
                            :loading="loading.productions">
                        </Multiselect>
                    </div>
                    <div class="custom-select links">
                        <label class="body-text">  Contract Type: </label>
                        <Multiselect v-model="filters.ContractTypes" :options="data.contractTypes"
                                placeholder="Type to search" :multiple="true" :hide-selected="true" :searchable="true"
                                :close-on-select="true" label="Name" track-by="ID" @select="handleContractChange"
                                @search-change="handleContractSearch" @open="handleContractOpen"
                                :loading="loading.contractTypes">
                            </Multiselect>
                    </div>
                </div>
                <div class="d-flex justify-content-between gap-20 w-100 mt-10">
                    <div class="d-flex justify-content-between gap-20">
                        <button class="btn btn-secondary" @click="resetFilters">Clear Filters</button>
                        <button class="btn btn-primary" v-if="contractInformation.length" @click="printList">Print List</button>
                    </div>

                    <button class="btn btn-primary" @click="searchData">
                        {{ loadingSearch ? 'Searching...' : 'Apply Filters' }}
                    </button>
                </div>
            </div>
        </div>
        <div v-if="!loadingSearch && contractInformation.length">
            <table class="small striped toggle no-underline mt-30" id="table-contract-info">
                <thead class="large large_padding">
                    <tr>
                        <th class="col-md-1">Employer</th>
                        <th class="col-md-1">Production</th>
                        <th class="col-md-1">Contract Type</th>
                        <th class="col-md-1">Contract Sub <br> Type</th>
                        <th class="col-md-1">Contract Begin <br>Date </th>
                        <th class="col-md-1">Contract End <br> Date </th>
                        <th class="col-md-1">Rate Group <br> Name</th>
                        <th class="col-md-2">Rate </th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="large"  v-for="(entry, index) in contractInformation" :key="index">
                        <td class="col-md-1">{{ entry.Employer }}</td>
                        <td class="col-md-1">{{ entry.Production }}</td>
                        <td class="col-md-1">{{ entry.Contract }}</td>
                        <td class="col-md-1">{{ entry.ContractSubType }}</td>
                        <td class="col-md-1">{{ entry.ContractBeginDate }}</td>
                        <td class="col-md-1">{{ entry.ContractEndDate }}</td>
                        <td class="col-md-1">{{ entry.RateGroupName }}</td>
                        <td class="col-md-2">
                            <div class="d-flex flex-column gap-2" v-for="(rate, key, mainIndex) in entry.Rate" :key="key">
                                <span class="fw-bold"> {{ key }} </span>
                                <span v-for="(value, index) in rate" :key="index">
                                    {{ value }}
                                </span>
                                <div v-if="mainIndex < Object.keys(entry.Rate).length - 1" class="divider mt-5 mb-5"> </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script lang="ts">
import { reactive, ref, Ref, computed } from 'vue';
import { Coverage, PlanType } from "@/interfaces/local";
import { usePrint } from '@/composable/usePrint';

import Multiselect from 'vue-multiselect';
import DateInput from '@components/form/DateInput.vue';
import SelectComponent from '@components/form/SelectComponent.vue';
import axios from 'axios';
import 'vue-multiselect/dist/vue-multiselect.css';

interface Filters {
    WorkPeriod: {
        StartDate?: string,
        EndDate?: string,
    };
    Employers: Employer[] | null;
    Productions: Employer[] | null;
    ContractTypes: Employer[] | null;
}

interface Employer {
    ID: string | number;
    Name: string;
}

interface Data {
    employers: Employer[];
    productions: Employer[];
    contractTypes: Employer[];
}

export default {
    components: {
        Multiselect,
        // SelectComponent,
        DateInput,
    },
    setup() {
        const loadingSearch = ref(false);
        const loading = reactive({
            employers: false,
            productions: false,
            contractTypes: false,
        })
        const allOption = { ID: 0, Name: 'ALL' };
        const contractInformation: Ref<Coverage[]> = ref([]);
        const { printElementById } = usePrint();
        const errors: Ref<string[]> = ref([]);
        const success = ref(null);
        const data: Data = reactive({
            employers: [],
            productions: [],
            contractTypes: [],
        })
        const initialFilters = {
            WorkPeriod: {
                StartDate: '',
                EndDate: '',
            },
            Employers: [allOption],
            Productions: [allOption],
            ContractTypes: [allOption],
        };
        const filters: Filters = reactive({ ...JSON.parse(JSON.stringify(initialFilters)) });
        const hasEmployers = computed(() => filters.Employers?.some(employer => employer.ID !== 0));
        const hasProductions = computed(() => filters.Productions?.some(prod => prod.ID !== 0));
        const hasContractTypes = computed(() => filters.ContractTypes?.some(contract => contract.ID !== 0));
        const printList = printElementById('table-contract-info', 'Summarized Contract Information');

        const resetFilters = () => {
            contractInformation.value = [];
            Object.assign(filters, JSON.parse(JSON.stringify(initialFilters)));
        }

        // Handle employers search
        const handleEmployersSearch = async (query: string) => {
            await handleSelectionSearch('employers', query);
        };

        // Handle productions search
        const handleProductionsSearch = async (query: string) => {
            await handleSelectionSearch('productions', query);
        };

        // Handle contract types search
        const handleContractSearch = async (query: string) => {
            await handleSelectionSearch('contractTypes', query);
        };

        const handleContractChange = async (newSelection: Employer) => {
            await handleSelectionChange('ContractTypes', newSelection);
        }

        const handleEmployerChange = async (newSelection: Employer) => {
            await handleSelectionChange('Employers', newSelection);
        }

        const handleProductionChange = async (newSelection: Employer) => {
            await handleSelectionChange('Productions', newSelection);
        }

        // Handle Search in Multiselect
        const handleSelectionSearch = async (type: string, query: string) => {
            loading[type as keyof Data] = true;
            let route = `api/local/${type}?query=${query}`

            if (type === 'productions') {
                const employerIds = filters.Employers?.map((employer: Employer) => {
                    if (employer.ID !== 0)
                        return employer.ID
                });

                route += `&&EmployerIDs=${employerIds}`;
            }

            try {
                const response = await axios.get(route);
                data[type as keyof Data] = response.data;
            }
            catch (error) {
                console.error(`Error searching ${type}:`, error);
            }

            loading[type as keyof Data] = false;
        }

        // Handle Change Selection
        const handleSelectionChange = async (type: string, newSelection: Employer) => {
            if (type !== 'WorkPeriod') {
                if (newSelection.ID === 0) {
                    filters[type as keyof typeof filters] = [allOption];
                }
                else {
                    const filteredTypes: Employer[] = JSON.parse(JSON.stringify(filters[type as keyof typeof filters])).filter((type: Employer) => type.ID !== 0)
                    filters[type as keyof typeof filters] = [...filteredTypes];

                    if (type === 'Employers') {
                        await handleProductions();
                    }
                }
            }
        };

        // Filter selected productions for selected employers
        const handleProductions = async () => {
            await handleProductionsSearch('');

            // if is not selected ALL option, filter selected productions
            if (filters.Productions && filters.Productions[0]?.ID !== 0) {
                const filteredProductions = filters.Productions?.filter(prod => data.productions.some(p => p.ID === prod.ID)) || [];
                filters.Productions = filteredProductions;
            }
        }

        const handleProductionsOpen = async (query: string) => {
            const employerIds = filters.Employers?.map((employer: Employer) => employer.ID);
            const keyword = query ?? '';

            if (!employerIds?.includes(0)) {
                await handleProductionsSearch(keyword);
            }
        };

        const handleContractOpen = async () => {
            setTimeout(() => {
                handleContractSearch('');
            }, 1000)
        };

        // Get Contract Information
        const searchData = async () => {
            loadingSearch.value = true;
            contractInformation.value = [];
            const formData = { ...filters };
            formData.Employers = !hasEmployers.value ? null : formData.Employers;
            formData.Productions = !hasProductions.value ? null : formData.Productions;
            formData.ContractTypes = !hasContractTypes.value ? null : formData.ContractTypes;

            await axios
                .post('api/local/contract-information', {
                    ...formData
                })
                .then(response => {
                    contractInformation.value = response.data;
                })
                .catch(error => {
                    if (error.response) {
                        errors.value = Object.values(
                            error.response.data.errors
                        ).flat() as string[];
                    }
                })
                .finally(() => {
                    loadingSearch.value = false;

                    setTimeout(() => {
                        success.value = null
                        errors.value = []
                    }, 3500);
                })
        }

        return {
            loading,
            errors,
            success,
            filters,
            data,
            hasEmployers,
            handleEmployerChange,
            handleProductions,
            handleProductionChange,
            handleContractChange,
            handleProductionsOpen,
            handleContractOpen,
            handleEmployersSearch,
            handleProductionsSearch,
            handleContractSearch,
            loadingSearch,
            contractInformation,
            resetFilters,
            searchData,
            printList,
        };
    }
}
</script>