<template>
  <AlertsComponent :errors="errors" :success="success" />

  <div class="pdt-30">
    <div class="d-flex justify-content-between align-items-center pdb-20">
      <h2>Locals</h2>
    </div>
    <div class="bordered-card bordered-card_secondary">
      <div class="filters d-flex gap-4 flex-wrap flex-md-nowrap justify-content-between w-100">
        <div class="d-flex gap-4 col-md-8 col-sm-12">
          <SelectComponent v-model:selectedOption="filters.Type" :options="types" :single-arrow="true"
            customClass="fs-xs" />
          <SelectComponent v-model:selectedOption="filters.Local" :options="localsFilterOptions" :single-arrow="true"
            customClass="fs-xs" :defaultOption="{ key: 0, value: 'Filter by Local #' }" />
        </div>
        <TextInput type="text" placeholder="Search" :max=50 v-model:modelValue="filters.OrgName"
          customClass="search col-3 col-sm-6" />
      </div>
      <LoaderComponent v-if="loading" />
      <LocalsRequests v-else-if="filters.Type.key == 0" :locals="pendingLocals" @send-pin="sendPin"
        @reject-request="rejectRequest" />
      <SearchLocalsList v-else :locals="locals" @reset-pin="resetPin" @activate="activateAccount"
        @deactivate="deactivateAccount" @change-role="changeRole" />
    </div>
    <PaginationComponent v-if="!loading" :paginator="paginator" @fetch-data="fetchPaginationData" />
  </div>
</template>

<script lang="ts">
import { reactive, ref, Ref, computed, onMounted, watch } from 'vue';
import { useRouter } from 'vue-router';
import {
  SearchLocal,
  SearchLocalsFormFilters,
  PendingLocal,
  Paginator,
} from "@/interfaces/admin";
import { SelectOption } from '@/interfaces/interfaces';
import { useOptionsFormatter } from '@/utils/optionFormatter';
import LocalsRequests from "@/components/superadmin/users/LocalsRequests.vue";
import SearchLocalsList from '@/components/superadmin/users/SearchLocalsList.vue';
import TextInput from '@components/form/TextInput.vue';
import SelectComponent from "@components/form/SelectComponent.vue";
import PaginationComponent from '@components/utils/PaginationComponent.vue';

import axios from "axios";

export default {
  components: {
    SearchLocalsList,
    LocalsRequests,
    TextInput,
    SelectComponent,
    PaginationComponent
  },
  setup() {
    const loading = ref(true);
    const searching = ref(false);
    const locals = ref<SearchLocal[]>([]);
    const pendingLocals = ref<PendingLocal[]>([]);
    const paginator: Paginator = reactive({
      per_page: 15, total: 100, last_page: 100, current_page: 1
    });
    const page = ref(1);
    const route = "api/administrator/users/locals";
    const router = useRouter();
    const success = ref(null);
    const errors: Ref<string[]> = ref([]);
    const types: SelectOption[] = useOptionsFormatter(['Pending Request', 'Manage All Accounts']);
    const localsFilterOptions = ref<SelectOption[]>([]);
    const filters: SearchLocalsFormFilters = reactive({
      OrgName: '',
      Local: null,
      Type: types[1],
    });

    const fetchData = async (isLoading: boolean) => {
      try {
        if (isLoading) loading.value = true;

        const response = await axios.get(route, {
          params: { ...filters, page: page.value },
        });

        locals.value = response.data.Locals;
        pendingLocals.value = response.data.PendingLocals;
        localsFilterOptions.value = useOptionsFormatter(response.data.LocalsOptions);
        console.log(localsFilterOptions.value);
        Object.assign(paginator, response.data.Paginator);
      }
      catch (error) {
        console.error("Error:", error);
      }

      if (isLoading) loading.value = false;
    };

    // Handle Send Pin
    const sendPin = async (requestID: number) => {
      router.push({ name: 'register', query: { RequestID: requestID } });
    };

    // Handle Reset Pin
    const resetPin = async (userID: number) => {
      const index = locals.value.findIndex(local => local.UserID === userID);

      try {
        locals.value[index].ResettingPin = true;
        const response = await axios.post(
          `api/administrator/users/reset-pin/${userID}`,
          { _method: "PATCH" }
        );
        success.value = response.data.success;
        if (response.data.error) {
          errors.value = Object.values(
            response.data.error
          ).flat() as string[];
        }
      }
      catch (error: any) {
        errors.value = Object.values(error.response.data.error).flat() as string[];
      }

      locals.value[index].ResettingPin = false;
      await refreshData();
    };

    // Handle Activate Account
    const activateAccount = async (userID: number) => {
      const index = locals.value.findIndex(local => local.UserID === userID);
      locals.value[index].Activating = true;

      try {
        const response = await axios.post(
          `${route}/activate/${userID}`,
          { _method: "PATCH" }
        );
        success.value = response.data.success;
      }
      catch (error) {
        console.error("Error activating account:", error);
      }

      await refreshData();
      locals.value[index].Activating = false;
    };

    // Handle Deactivate Account
    const deactivateAccount = async (userID: number) => {
      const index = locals.value.findIndex(local => local.UserID === userID);
      locals.value[index].Deactivating = true;

      try {
        const response = await axios.post(
          `${route}/deactivate/${userID}`,
          { _method: "PATCH" }
        );
        success.value = response.data.success;
      }
      catch (error) {
        console.error("Error deactivating account:", error);
      }

      await refreshData();
      locals.value[index].Deactivating = false;
    };

    // Handle Reject Request
    const rejectRequest = async (requestID: number) => {
      try {
        const index = pendingLocals.value.findIndex(req => req.UILocalRequestID === requestID);
        pendingLocals.value[index].Rejecting = true;
        const response = await axios.post(
          `${route}/reject/${requestID}`,
          { _method: "PATCH" }
        );

        success.value = response.data.success;
        await refreshData();
        pendingLocals.value[index].Rejecting = false;
      }
      catch (error) {
        console.error("Error rejecting request:", error);
      }
    };

    // Handle Change Role
    const changeRole = async (userID: number) => {
      const index = locals.value.findIndex(local => local.UserID === userID);
      locals.value[index].ChangingRole = true;

      try {
        const response = await axios.post(
          `${route}/change-role/${userID}`,
          { _method: "PATCH" }
        );
        success.value = response.data.success;
      }
      catch (error) {
        console.error("Error changing role:", error);
      }

      await refreshData();
      locals.value[index].ChangingRole = false;
    };

    const refreshData = async () => {
      await fetchData(false);

      setTimeout(() => {
        success.value = null;
        errors.value = [];
      }, 3500);
    };

    // Fetch Data For Selected Page
    const fetchPaginationData = (pageNumber: number) => {
      page.value = pageNumber;
      fetchData(false);
    };

    watch(filters, async () => {
      searching.value = true;
      page.value = 1;
      await fetchData(false);
      searching.value = false;
    }, { deep: true });

    watch(
      () => filters.Type,
      async (newVal, oldVal) => {
        if (newVal !== oldVal) {
          searching.value = true;
          filters.Local = null;
          filters.OrgName = '';
          page.value = 1;
          await fetchData(true);
          filters.Local = localsFilterOptions.value[0];
          searching.value = false;
        }
      }
    );

    onMounted(async () => await fetchData(true));

    return {
      filters,
      localsFilterOptions,
      types,
      locals,
      pendingLocals,
      loading,
      searching,
      paginator,
      success,
      errors,
      sendPin,
      rejectRequest,
      resetPin,
      activateAccount,
      deactivateAccount,
      changeRole,
      fetchPaginationData,
    };
  },
};
</script>