<template>
    <div class="plan-content">
        <div class="plan-cards-wrapper">
            <div class="card-header">
                <h5>Plan C-MRP Details</h5>
                <div class="card-control plus">
                    <i class="plus iatse-icon-plus"></i>
                    <span class="line"></span>
                </div>
            </div>
        </div>

        <div class="container-fluid">
            <div class="row">
                <!-- Main Participant Details (flex container) -->
                <div class="col-md-8">
                    <div class="coverage-details mb-4">
                        <div class="d-flex flex-wrap coverage-details__row ">
                            <div class="coverage-details__item flex-grow-1">
                                <strong>Participant ID</strong>
                                <p>{{ participantDetails.ParticipantID ?? 'N/A' }}</p>
                            </div>
                            <div class="coverage-details__item flex-grow-1">
                                <strong>SSN</strong>
                                <p>{{ participantDetails.SSN ?? 'N/A' }}</p>
                            </div>
                            <div class="coverage-details__item flex-grow-1">
                                <strong>Name</strong>
                                <p>{{ participantDetails.Name ?? 'N/A' }}</p>
                            </div>
                            <div class="coverage-details__item flex-grow-1">
                                <strong>Date of Birth</strong>
                                <p>{{ participantDetails.DateofBirth ?? 'N/A' }}</p>
                            </div>
                            <div class="coverage-details__item flex-grow-1">
                                <strong>Gender</strong>
                                <p>{{ participantDetails.Gender ?? 'N/A' }}</p>
                            </div>
                            <div class="coverage-details__item w-100">
                                <strong>Address</strong>
                                <p>{{ participantDetails.AddressFormatted ?? 'N/A' }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Coverage Status (flex container) -->
                <div class="col-md-4">
                    <div class="coverage-details">
                        <div class="d-flex flex-wrap coverage-details__row" v-if="dataLoaded">
                            <div class="coverage-details__item flex-basis-half">
                                <strong>Coverage Status</strong>
                                <p>Plan {{ planSummary.Coverage.Plan }}</p>
                            </div>
                            <div class="coverage-details__item flex-basis-half">
                                <strong>Start of Coverage</strong>
                                <p>{{ planSummary.Coverage.From }}</p>
                            </div>
                            <div class="coverage-details__item flex-basis-half">
                                <strong>Available RMRP Balance</strong>
                                <p>{{ formatCurrency(planSummary.Coverage.RmrpBalance) }}</p>
                            </div>
                            <div class="coverage-details__item flex-basis-half">
                                <strong>End of Coverage</strong>
                                <p>{{ planSummary.Coverage.To }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Upcoming Plans Card Navigation -->
        <div class="plan-upcoming">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-10">
                        <div class="plan-general-details plan-navigation">
                            <div class="plan-upcoming-navigation">
                                <!-- Previous Period Button -->
                                <a @click.prevent="navigateToPrevious" :disabled="!canMovePrev" class="btn btn-primary btn-navigation btn-prev">
                                    <i class="iatse-icon-arrow-down"></i>
                                </a>

                                <!-- Display only 3 periods -->
                                <div v-for="(period, index) in formattedPeriods" :key="index" :class="['period', { 'current-period': period.IsCurrent, 'active-period': period.IsActive }]">
                                    {{ period.FromFormatted }} - {{ period.ToFormatted }}
                                    <label v-if="period.IsCurrent">Current</label>
                                    <!-- <label v-else-if="!period.IsCurrent">Upcoming</label> -->
                                </div>

                                <!-- Next Period Button -->
                                <a @click.prevent="navigateToNext" :disabled="!canMoveNext" class="btn btn-primary btn-navigation btn-next">
                                    <i class="iatse-icon-arrow-down"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <a 
                        href="#"
                        class="btn btn-tertiary"
                        @click.prevent="openModal('hw-capp-payments-history', 'Capp Statements History', '', null, null, null)"
                        >Capp Statement History</a>
                    </div>
                </div>
            </div>
        </div>

        <!-- Contribution Table -->
        <div class="contribution-table">

            <!-- Contribution Account -->
            <div class="capp-account bordered-card width-30">
                <h4> CAPP Account </h4>
                <div class="contribution-left-table" v-if="dataLoaded && planSummary.CappAccount && Object.keys(planSummary.CappAccount).length > 0">
                    <div class="contribution-left-line">
                        <h5> Previous Balance: </h5>
                        <h5> {{ formatCurrency(planSummary.CappAccount.PREVBAL) }} </h5>
                    </div>
                    <div class="contribution-left-lines">
                        <div class="contribution-left-line">
                            <h5 class="month" :class="{ 'current': selectedMonth === planSummary.CappAccount.CONTRIB1?.MonthName }" @click="selectMonth(planSummary.CappAccount.CONTRIB1?.MonthName)">
                                {{ formatDateToMonth(planSummary.CappAccount.CONTRIB1?.MonthName) }}
                            </h5>
                            <h5 class="flex"> {{ formatCurrency(planSummary.CappAccount.CONTRIB1.Value) }} </h5>
                        </div>
                        <div class="contribution-left-line">
                            <h5 class="month" :class="{ 'current': selectedMonth === planSummary.CappAccount.CONTRIB2?.MonthName }" @click="selectMonth(planSummary.CappAccount.CONTRIB2?.MonthName)">
                                {{ formatDateToMonth(planSummary.CappAccount.CONTRIB2?.MonthName) }}
                            </h5>
                            <h5 class="flex"> {{ formatCurrency(planSummary.CappAccount.CONTRIB2.Value) }} </h5>
                        </div>
                        <div class="contribution-left-line">
                            <h5 class="month" :class="{ 'current': selectedMonth === planSummary.CappAccount.CONTRIB3?.MonthName }" @click="selectMonth(planSummary.CappAccount.CONTRIB3?.MonthName)">
                                {{ formatDateToMonth(planSummary.CappAccount.CONTRIB3?.MonthName) }}
                            </h5>
                            <h5 class="flex"> {{ formatCurrency(planSummary.CappAccount.CONTRIB3.Value) }} </h5>
                        </div>
                    </div>
                    <div class="contribution-left-line">
                        <h5> Total Contributions </h5>
                        <h5> {{ formatCurrency(planSummary.CappAccount.TOTCONT) }} </h5>
                    </div>
                    <div class="contribution-left-lines">
                        <div class="contribution-left-line">
                            <h5> Admin Fees: </h5>
                            <h5> {{ formatCurrency(planSummary.CappAccount.ADMINFEE) }}  </h5>
                        </div>
                        <div class="contribution-left-line">
                            <h5 class="link-text"> Medical Reimbursements: </h5>
                            <h5> {{ formatCurrency(planSummary.CappAccount.REIMB) }} </h5>
                        </div>
                        <div class="contribution-left-line">
                            <h5> Amount Available: </h5>
                            <h5> {{ formatCurrency(planSummary.CappAccount.AMTAVAIL) }} </h5>
                        </div>
                        <div class="contribution-left-line">
                            <h5 class="link-text"> Co-Payment: </h5>
                            <h5> {{ formatCurrency(planSummary.CappAccount.COPAY) }} </h5>
                        </div>
                        <div class="contribution-left-line">
                            <h5> Premium Payment: </h5>
                            <h5> {{ formatCurrency(planSummary.CappAccount.PREMPAY) }} </h5>
                        </div>
                        <div class="contribution-left-line">
                            <h5> Transfers/Waivers: </h5>
                            <h5> $172.84 </h5>
                        </div>
                    </div>
                    <div class="contribution-left-line">
                        <h5> Ending Balance </h5>
                        <h5> {{ formatCurrency(planSummary.CappAccount.BALANCE) }} </h5>
                    </div>
                </div>
            </div>

            <!-- Contribution Tables -->
            <div class="contributions-tables">
                <div class="contributions-table-wrapper">
                    <div class="contributions-table-item">
                        <h4> Contribution Detail </h4>

                        <table>
                            <thead>
                                <tr>
                                    <th>Receipt Date</th>
                                    <th>Wk End</th>
                                    <th>Posted</th>
                                    <th>Employer/Production</th>
                                    <th>Report</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            <tbody v-if="filteredContributionDetails && filteredContributionDetails.length > 0">
                                <tr v-for="contribution in filteredContributionDetails" :key="contribution.ReportID">
                                    <td>{{ contribution.ReceiptDate }}</td>
                                    <td>{{ contribution.PeriodEnd }}</td>
                                    <td>{{ contribution.PostingDate }}</td>
                                    <td>{{ contribution.Employer }} / {{ contribution.Production }}</td>
                                    <td>{{ contribution.ReportID }}</td>
                                    <td>{{ formatCurrency(contribution.Amount) }}</td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td colspan="6">No contributions found.</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="contributions-table-wrapper">
                    <div class="contributions-table-item">
                        <h4> Statement Activity </h4>
                        <table>
                            <thead>
                                <tr>
                                    <th>Coverage Begins</th>
                                    <th>Type</th>
                                    <th>Date Sent</th>
                                    <th>Status</th>
                                    <th>Due Date</th>
                                    <th>Balance</th>
                                </tr>
                            </thead>
                            <tbody v-if="planSummary.CappStatements && planSummary.CappStatements.length > 0">
                                <tr v-for="statement in planSummary.CappStatements" :key="statement.ID">
                                    <td>{{ statement.CoverageBegins }}</td>
                                    <td>{{ statement.Type }}</td>
                                    <td>{{ statement.DateSent }}</td>
                                    <td>{{ statement.Status }}</td>
                                    <td>{{ statement.DueDate }}</td>
                                    <td>{{ formatCurrency(statement.Balance) }}</td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td colspan="6">No statements found.</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <!-- Account Actions -->
        <div class="plan-summary__footer row w-100">
            <div class="col-12 col-md-4">
                <h4>Other Coverages</h4>
                <div class="account-actions">
                    <!-- Other Coverages -->
                    <div class="action-buttons">

                    </div>
                </div>
            </div>
            <div class="col-12 col-md-8">
                <h4>Account Actions</h4>
                <div class="action-buttons mt-10">
                    <a href="" class="btn btn-secondary plan-modal"
                        @click.prevent="openModal('beneficiaries', 'My Beneficiaries', 'plan-tables-striped beneficiary', null, null, null
                        )">
                        Beneficiaries
                    </a>
                    <a 
                        href="#"
                        class="btn btn-secondary btn-large plan-modal"
                        @click.prevent="openModal('claims', 'Claims', 'plan-upload plan-card-info plan-c-mrp-claims')">
                        Claims
                    </a>
                    <a href="" class="btn btn-secondary btn-large plan-modal"
                        @click.prevent="openModal('dependents', 'My Dependents', null)">My Dependents
                    </a>
                </div>
            </div>
        </div> <!-- end actions -->
    </div>
</template>

<script lang="ts">
import { ref, computed, onMounted } from 'vue';
import axios from 'axios';
import { useModalStore } from '@/stores/modal';
import { useParticipantStore } from '@/stores/participant'
import { useCurrencyFormatter } from "@/composable/useCurrencyFormatter";
import { PlanCSummary, CoveragePeriodSliderItem } from '@/interfaces/health';
import { useCoverageStore } from '@/stores/coverage';

export default {
    setup() {
        const dataLoaded = ref(false);
        const modalStore = useModalStore();
        const openModal = modalStore.openModal;
        const participantStore = useParticipantStore();
        const participantDetails = participantStore.general;
        const { formatCurrency } = useCurrencyFormatter();
        const coverageStore = useCoverageStore();
        const selectedMonth = ref<string | null>(null);

        // Store periods separately to avoid refetching
        const periods = ref<CoveragePeriodSliderItem[]>([]);

        // Plan summary without periods
        const planSummary = ref<PlanCSummary>({
            PersonID: 0,
            Coverage: {
                From: '',
                To: '',
                PlanClass: null,
                Plan: ''
            },
            PlanCDetails: {
                CombinedAccount: false,
                AdministrativeFee: false,
                EnrollmentForm: 'N/A',
                Lapse: 'N/A',
                Forfeitures: 'N/A',
                HasQmsco: false
            },
            CappAccount: {
                PREVBAL: 0,
                CONTRIB1: { MonthName: '', Value: 0 },
                CONTRIB2: { MonthName: '', Value: 0 },
                CONTRIB3: { MonthName: '', Value: 0 },
                ADMINFEE: 0,
                TOTCONT: 0,
                REIMB: 0,
                ADJUST: 0,
                AMTAVAIL: 0,
                COPAY: 0,
                PREMPAY: 0,
                BALANCE: 0,
                InvoiceID: null,
                InvoicePeriod: null,
                InvoicePlan: null,
            },
            MrpFrom: '',
            MrpTo: '',
            ContributionDetails: [],
            CappStatements: [],
            MrpBalance: 0,
            OtherCoverages: {
                Retiree: { Show: false },
                C: { Show: false },
                A: { Show: false }
            }
        });

        const currentPeriodIndex = ref<number>(3); // Starting index for current period

        // Flag to check if periods are already fetched
        const periodsFetched = ref<boolean>(false);

        // Computed to get visible periods (previous, current, next)
        const visiblePeriods = computed(() => {
            const start = Math.max(currentPeriodIndex.value - 1, 0);
            const end = Math.min(currentPeriodIndex.value + 2, periods.value.length);
            return periods.value.slice(start, end);
        });

        const canMovePrev = computed(() => currentPeriodIndex.value > 0);
        const canMoveNext = computed(() => currentPeriodIndex.value < periods.value.length - 1);

        // Helper function to update the IsActive flag
        const updateIsActive = () => {
            periods.value.forEach((period, index) => {
                period.IsActive = index === currentPeriodIndex.value;
            });
        };

        // Fetch data from the API, sending a flag if periods are already fetched
        const fetchData = (fromDate: string | null = null, toDate: string | null = null) => {
            const params = {
                FromDate: fromDate,
                ToDate: toDate,
                HasPeriods: periodsFetched.value, // Flag to skip fetching periods
            };

            axios
                .get('/api/participant/health-welfare/plan-rmrp-summary', { params })
                .then((response) => {
                    // If periods have not been fetched yet, store them
                    if (!periodsFetched.value) {
                        periods.value = response.data.Periods;
                        periodsFetched.value = true;

                        // Set IsActive for the initial load
                        updateIsActive();
                    }
                    // Store other plan summary details
                    planSummary.value = {
                        ...response.data,
                        Periods: [] // Remove periods from planSummary as they are stored separately
                    };
                    selectedMonth.value = planSummary.value.CappAccount?.CONTRIB1?.MonthName || null;
                    dataLoaded.value = true;
                })
                .catch((error) => {
                    console.error('Error fetching data:', error);
                });
        };

        // Navigate to the previous period
        const navigateToPrevious = () => {
            if (canMovePrev.value) {
                dataLoaded.value = false;
                currentPeriodIndex.value--;
                const selectedPeriod = periods.value[currentPeriodIndex.value];
                fetchData(selectedPeriod.From, selectedPeriod.To);

                // Update IsActive status
                updateIsActive();
            }
        };

        // Navigate to the next period
        const navigateToNext = () => {
            if (canMoveNext.value) {
                dataLoaded.value = false;
                currentPeriodIndex.value++;
                const selectedPeriod = periods.value[currentPeriodIndex.value];
                fetchData(selectedPeriod.From, selectedPeriod.To);

                // Update IsActive status
                updateIsActive();
            }
        };

        function selectMonth(monthName: string | null) {
            if (selectedMonth.value === monthName) {
                // Clear the filter if the same month is clicked again
                selectedMonth.value = null;
            } else {
                selectedMonth.value = monthName;
            }
        }

        function getMonthYearFromDate(dateString: string): { month: string, year: string } {
            const parts = dateString.split('/');
            if (parts.length === 3) {
                // Format: MM/DD/YYYY
                const [month, , year] = parts;
                return { month, year };
            } else if (parts.length === 2) {
                // Format: MM/YYYY
                const [month, year] = parts;
                return { month, year };
            } else {
                return { month: '', year: '' };
            }
        }

        function formatFromDate(dateString: string): string {
            const dateParts = dateString.split('/');
            if (dateParts.length !== 3) {
                return dateString; // Return as-is if the format is unexpected
            }
            const [month, , year] = dateParts;
            const dateObj = new Date(parseInt(year), parseInt(month) - 1);
            const options: Intl.DateTimeFormatOptions = { month: 'long' }; // Full month name
            return dateObj.toLocaleDateString('en-US', options);
        }

        function formatToDate(dateString: string): string {
            const dateParts = dateString.split('/');
            if (dateParts.length !== 3) {
                return dateString; // Return as-is if the format is unexpected
            }
            const [month, , year] = dateParts;
            const dateObj = new Date(parseInt(year), parseInt(month) - 1);
            const options: Intl.DateTimeFormatOptions = { month: 'long', year: 'numeric' }; // Full month name and year
            return dateObj.toLocaleDateString('en-US', options);
        }

        const formattedPeriods = computed(() => {
            return visiblePeriods.value.map(period => ({
                ...period,
                FromFormatted: formatFromDate(period.From),
                ToFormatted: formatToDate(period.To),
            }));
        });

        const filteredContributionDetails = computed(() => {
            if (!selectedMonth.value) {
                return planSummary.value.ContributionDetails;
            }

            const { month: selectedMonthNum, year: selectedYear } = getMonthYearFromDate(selectedMonth.value);

            return planSummary.value.ContributionDetails.filter(detail => {
                if (!detail.PeriodEnd) return false;

                const { month: detailMonth, year: detailYear } = getMonthYearFromDate(detail.PeriodEnd);

                return detailMonth === selectedMonthNum && detailYear === selectedYear;
            });
        });

        const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        const formatDateToMonth = (dateString: string) => {
            const [month, year] = dateString.split('/');
            const monthIndex = parseInt(month, 10) - 1;
            return `${monthNames[monthIndex]} ${year}`;
        };

        onMounted(() => {
            fetchData(); // Initial fetch on mount
        });

        return {
            participantDetails,
            openModal,
            planSummary,
            currentPeriodIndex,
            visiblePeriods,
            canMovePrev,
            canMoveNext,
            dataLoaded,
            navigateToPrevious,
            navigateToNext,
            formatDateToMonth,
            formatCurrency,
            filteredContributionDetails,
            getMonthYearFromDate,
            selectedMonth,
            selectMonth,
            //
            formattedPeriods,
            formatFromDate,
            formatToDate
        };
    },
};
</script>