<template>
    <div class="bordered-card bordered-card_secondary gap-0">
        <h4 class="fw-bold mb-25">Recent Announcements</h4>
        <template v-for="(fund, fundName) in announcements" :key="fundName">
            <div class="primary-group d-flex justify-content-between align-items-center w-100 plan-modal"
                @click="toggleGroup(fundName)" :class="{
                    'mb-20': !(fundName === Object.keys(announcements)[Object.keys(announcements).length - 1]) && !showGroup[fundName],
                    'open': showGroup[fundName]
                }">
                <div class="d-flex gap-10 align-items-center">
                    <i :class="`iatse-icon-${fund.Icon}`" class="fs-xl"></i>
                    <h5 class="fw-bold"> {{ fundName }}</h5>
                </div>
                <!-- Toggle secondary group items on click -->
                <button class="toggle-btn">
                    <span v-if="!showGroup[fundName]" class="fs-xl lh-1">+</span>
                    <span v-else class="fs-xl lh-1">-</span>
                </button>
            </div>
            <transition name="slide-fade">
                <div v-if="showGroup[fundName]"
                    class="secondary-group bg-blue d-flex flex-column justify-content-between pdl-10 pdr-10 gap-15 w-100 mb-20 pdb-10">
                    <template v-for="(announcement, key) in fund.Items" :key="key">
                        <div class="d-flex justify-content-between flex-wrap flex-md-nowrap gap-20">
                            <span class="label label_white order-1 order-md-0"> <span class="bullet">
                                </span>{{ announcement.Date }} </span>
                            <h4 class="fw-light order-3 order-md-1">{{ announcement.Title }} </h4>
                            <h5 v-if="!showMore[key]" class="order-2 order-md-2"><a href="javascript;"
                                    @click.prevent="toggleMore(key)">Read More</a></h5>
                            <h5 v-if="showMore[key]" class="order-2 order-md-2"><a href="javascript;"
                                    @click.prevent="toggleMore(key)">Read Less</a></h5>
                        </div>
                        <transition name="slide-fade">
                            <p v-if="showMore[key]"> {{ announcement.ReadMore }} </p>
                        </transition>
                    </template>
                </div>
            </transition>
        </template>
    </div>
</template>

<script lang="ts">
import { PropType, ref, defineComponent } from 'vue';
import { Announcements } from '@interfaces/local';

export default defineComponent({
    props: {
        announcements: [] as PropType<Announcements>
    },

    setup() {
        const showGroup = ref<Record<string, boolean>>({});
        const showMore = ref<Record<string, boolean>>({});

        // Toggle the visibility of secondary items for each group
        const toggleGroup = (fundName: string) => {
            showGroup.value[fundName] = !showGroup.value[fundName];
        };

        // Toggle the visibility of more text for each group
        const toggleMore = (key: string) => {
            showMore.value[key] = !showMore.value[key];
        };

        return {
            showGroup,
            showMore,
            toggleGroup,
            toggleMore,
        }
    }
})
</script>