<template>
    <div :class="['verification-code-inputs', extraClasses]">
      <input
        v-for="(digit, index) in verificationCodeDigits"
        :key="index"
        type="text"
        maxlength="1"
        class="verification-code-input"
        :value="digit"
        @input="onDigitInput($event, index)"
        @keydown.backspace="onBackspace(index, $event)"
        @focus="onInputFocus(index)"
        @blur="onInputBlur(index)"
        @paste="onPaste($event)"
        :ref="setDigitInputRef(index)"
      />
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent, ref, Ref, onMounted, nextTick } from 'vue';
  
  export default defineComponent({
    name: 'VerificationCodeInput',
    props: {
      length: {
        type: Number,
        default: 6,
      },
      modelValue: {
        type: String,
        default: '',
      },
      extraClasses: {
        type: String,
        default: '', // Allows passing additional classes for styling
      },
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
      const verificationCodeDigits = ref(Array(props.length).fill('-'));
      const digitInputs: Ref<(HTMLInputElement | null)[]> = ref([]);
  
      const onDigitInput = (event: Event, index: number) => {
        const input = event.target as HTMLInputElement;
        const value = input?.value || '';
  
        if (/^\d$/.test(value)) {
          verificationCodeDigits.value[index] = value;
          emit('update:modelValue', verificationCodeDigits.value.join(''));
  
          if (index < props.length - 1) {
            focusInput(index + 1);
          }
        } else {
          input.value = verificationCodeDigits.value[index] || '';
        }
      };
  
      const onBackspace = (index: number, event: KeyboardEvent) => {
        if (event.key === 'Backspace' || event.key === 'Delete') {
          verificationCodeDigits.value[index] = '-';
          emit('update:modelValue', verificationCodeDigits.value.join(''));
  
          if (event.key === 'Backspace' && index > 0) {
            focusInput(index - 1);
          }
        }
      };
  
      const onPaste = async (event: ClipboardEvent) => {
        event.preventDefault();
        const pasteData = event.clipboardData?.getData('text') || '';
        const regex = new RegExp(`^\\d{${props.length}}$`);
        if (regex.test(pasteData)) {
          verificationCodeDigits.value = pasteData.split('');
          emit('update:modelValue', verificationCodeDigits.value.join(''));
          await nextTick();
          focusInput(props.length - 1);
        }
      };
  
      const onInputFocus = (index: number) => {
        if (verificationCodeDigits.value[index] === '-') {
          verificationCodeDigits.value[index] = '';
        }
      };
  
      const onInputBlur = (index: number) => {
        if (!verificationCodeDigits.value[index]) {
          verificationCodeDigits.value[index] = '-';
        }
      };
  
      const focusInput = (index: number) => {
        digitInputs.value[index]?.focus();
      };
  
      const setDigitInputRef = (index: number) => (el: HTMLInputElement | null) => {
        digitInputs.value[index] = el;
      };
  
      onMounted(() => {
        focusInput(0);
      });
  
      return {
        verificationCodeDigits,
        onDigitInput,
        onBackspace,
        onPaste,
        onInputFocus,
        onInputBlur,
        setDigitInputRef,
      };
    },
  });
  </script>
  
  <style scoped>
  .verification-code-inputs {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 400px;
  }
  
  .verification-code-inputs.centered {
    margin: 0 auto;
  }
  
  .verification-code-input {
    width: 50px;
    height: 50px;
    font-size: 24px;
    text-align: center;
    border: 2px solid #ccc;
    border-radius: 8px;
    outline: none;
    background-color: #f9f9f9;
  }
  
  .verification-code-input:focus {
    border-color: #007bff;
    background-color: #fff;
  }
  </style>
  