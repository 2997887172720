<template>
    <AlertsComponent :errors="errors" />
    <div class="plan-content">
        <BeneficiaryCard v-if="isAddAction && newBeneficiariesCard.length" title="Beneficiary" mode="multiple"
            :data="newBeneficiariesCard" :deleteItem="true" :hasDivider="true" customClass="green-card beneficiary-card"
            @delete="deleteItem" />
        <form id="assign-fund-form" class="form assign-fund" method="post">
            <h2> Select Fund </h2>
            <FundCard v-for="(fund, key) in funds" :title="fund" :key="key" @show-buttons="handleShowButtons" />

            <div v-if="showActionButtons" class="action-buttons">
                <button class="btn btn-primary plan-modal" @click.prevent="handleSave" :disabled="loading">
                    {{ saveButtonText }}
                </button>
                <button type="button" @click.prevent="resetAssignments" class="btn btn-secondary" :disabled="loading"> Discard </button>
            </div>
        </form>
    </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref, onMounted } from 'vue';
import { useModalStore } from '@/stores/modal';
import { useBeneficiaryStore } from '@/stores/beneficiary';

import BeneficiaryCard from '@/components/cards/BeneficiaryCard.vue';
import FundCard from '@/components/cards/FundCard.vue';

export default defineComponent({
    components: {
        BeneficiaryCard,
        FundCard
    },
    setup(_, { emit }) {
        const loading = ref(false);
        const showButtons = ref(false);
        const errors = ref<string[]>([]);
        const modalStore = useModalStore();
        const beneStore = useBeneficiaryStore();
        const openModal = modalStore.openModal;
        const newBeneficiariesCard = computed(() => beneStore.beneCards);
        const showActionButtons = computed(() => beneStore.isAddAction || showButtons.value || beneStore.isDeleteAction ? true : false);
        const saveButtonText = computed(() => {
            if (loading.value) {
                return 'Submitting';
            } else if (beneStore.isAddAction || beneStore.isDeleteAction) {
                return 'Save beneficiaries';
            }

            return 'Update Assignment';
        });

        const handleSubmit = async () => {
            loading.value = true;
            beneStore.filterAssignments();
            errors.value = await beneStore.handleSubmitNewBenes(false) ?? [];

            if (!errors.value.length) {
                await beneStore.fetchBeneficiaries();
                beneStore.reloadAssignments();

                modalStore.closeAbove(0);
            }

            setTimeout(() => errors.value = [], 2000)

            loading.value = false;
        }

        const modalProps = {
            title: 'Fund Assigned',
            content: {
                type: "text",
                value: beneStore.beneficiaryNames,
                icon: 'iatse-icon-user-verified'
            },
            onConfirmButton: 'Save beneficiaries',
            onConfirm: handleSubmit
        }

        const deleteItem = (index: number) => {
            beneStore.newBeneficiaries.splice(index, 1);
            beneStore.newBeneficiariesCard.splice(index, 1);
            beneStore.reloadAssignments();
        }

        const closeModal = () => {
            emit('close');
        }

        const resetAssignments = () => {
            beneStore.discard = false;
            beneStore.getSliders();
            beneStore.getBeneCards();
            beneStore.initAssignments();
            beneStore.discard = true;
        }

        const handleShowButtons = () => {
            showButtons.value = true
        }

        const handleSave = () => {
            if (beneStore.isAddAction) {
                if (!beneStore.newBeneficiaries.length) {
                    errors.value.push('Please add at least one Beneficiary!');
                } else {
                    openModal('success-modal', null, 'modal-success', null, modalProps)
                }
            }
            else {
                handleSubmit();
            }
        }

        onMounted(() => {
            beneStore.getSliders();
            beneStore.getBeneCards();
            beneStore.initAssignments();
        })

        return {
            errors,
            loading,
            isAddAction: beneStore.isAddAction,
            funds: beneStore.eligibleFunds,
            newBeneficiariesCard,
            showActionButtons,
            saveButtonText,
            handleShowButtons,
            handleSave,
            closeModal,
            deleteItem,
            resetAssignments,
        };
    },
})
</script>