<template>
    <Alerts :errors="errors" :success="success" />
    <MessageCard message="If you wish to change your password, email or security question, please do so in the
                        corresponding boxes below." type="danger" iconClass="iatse-icon-alarm"
        classes="info_card_full" />

    <LoaderComponent v-if="loading" />
    <div v-else class="plan-content">
        <!-- Update Email -->
        <form id="email-change-form" class="form account-settings" @submit.prevent="updateEmail">
            <h4 class="w-100"> Account Email </h4>
            <div class="w-full d-flex gap-3">
                <TextInput type="email" v-model:modelValue="email" label="Email" :placeholder=userEmail />
                <div class="w-full">
                    <button type="submit" class="btn btn-secondary toggle-control" :disabled="loadingUpdate['email']"> Update </button>
                </div>
            </div>
        </form>

            <!-- Update Email -->
        <form id="email-change-form" class="form account-settings" @submit.prevent="updatePhone">
            <h4 class="w-100"> Mobile phone (for 2FA) </h4>
            <div class="w-full d-flex gap-3">
                <TextInput type="tel" label="Verified Phone numberd" placeholder="******3333" :max=12 v-model="phone" />
                <div class="w-full">
                    <button type="submit" class="btn btn-secondary toggle-control" :disabled="loadingUpdate['phone']"> Update </button>
                </div>
            </div>
        </form>

        <!-- Update Password -->
        <form id="password-change-form" class="form account-settings" @submit.prevent="updatePassword">
            <h4 class="w-100"> Password </h4>
            <TextInput type="password" label="Current Password" placeholder="Current password"
                v-model:modelValue="currentPassword" />

            <a href="" @click.prevent="verifyPassword" class="btn btn-secondary initial-btn toggle-control"> Update </a>

            <transition name="slide-fade">
                <div class="w-full update-inputs content-toggle" v-if="updateSettingsState.password">
                    <TextInput type="password" label="New Password" placeholder="Enter New password"
                        v-model:modelValue="password" name="password" />

                    <TextInput type="password" label="Confirm New Password" placeholder="Enter Confirm New password"
                        v-model:modelValue="passwordConfirmation" name="password_confirmation" />
                    <div class="w-full">
                        <div class="w-full">
                            <button type="submit" class="btn btn-primary toggle-control"
                                :disabled="loadingUpdate['password']"> Update </button>
                            <a href="" class="btn btn-secondary toggle-control"
                                @click.prevent="toggleUpdateSetting('password')"> Discard </a>
                        </div>
                    </div>
                </div>
            </transition>
        </form>

        <!-- 2 Factor Authentication -->
        <form id="two-fa-form">
            <p>Preferred Method</p>
            <div class="radio-group bold">
                <div class="radio-buttons">
                    <div class="radio-button primary w-fit align-items-start">
                        <input
                            type="radio"
                            id="participant"
                            name="method"
                            v-model="preferredMethod"
                            class="pdr-5"
                            value="P"
                            :disabled="!phone"
                            @click="updatePreferredMethod('P')"
                        />
                        <label for="participant" class="pdr-15">Mobile Phone</label>
                    </div>
                    <div class="radio-button primary w-fit align-items-start">
                        <label for="participant"><input
                            type="radio"
                            id="participant"
                            name="method"
                            v-model="preferredMethod"
                            class="pdr-5"
                            value="E"
                            @click="updatePreferredMethod('E')"
                            /> Email Code</label>
                    </div>
                </div>
            </div>
            <small v-if="!phone">Please add your phone number above to enable this option.</small>
        </form>
        <!-- Update Security Questions -->
        <form id="security-questions-form" class="form account-settings" @submit.prevent="updateSecurityQuestion">
            <h4> Security Questions </h4>
            <div v-for="index in numberOfQuestions" :key="index" class="w-full">
                <SecurityQuestionSelect :questions="availableQuestionsForSelect(`select${index}`).value"
                    label="Security Question"
                    :userQuestion="userQuestions[index - 1]"
                    :selectedQuestions="selectedQuestions"
                    :questionId="userQuestions[index - 1]?.ID"
                    :index="index"
                    :is-editable="isSQEditable"
                    :resetInputs="resetInputs"
                    :options="securityQuestionsArray"
                    :showAddNewQuestion="showAddNewQuestion"
                    @handle-question="handleQuestion"
                    @toggle-show-button="addNewQuestion" />
            </div>

            <div class="w-full" v-if="!userQuestions[0]?.SecurityQuestionID && !showAddNewQuestion">
                <a href="" @click.prevent="addNewQuestion" class="btn btn-secondary add-btn">
                    <p class="icon-wrapper"> <i class="iatse-icon-plus"> </i> </p> Add New Security Question
                </a>
            </div>
        </form>
    </div>
</template>

<script lang="ts">
import { ref, onMounted, Ref, computed } from 'vue';
import { SecurityQuestion, SelectedQuestionType } from '@/interfaces/interfaces';
import { SelectedQuestion } from '@/interfaces/employer';
import { useAuthStore } from '@/stores/auth';
import { useModalStore } from '@/stores/modal';
import axios from 'axios';

import SecurityQuestionSelect from '@components/user/SecurityQuestionSelect.vue';
import TextInput from '@components/form/TextInput.vue';
import MessageCard from '@components/cards/MessageCard.vue';
import Alerts from '@components/utils/AlertsComponent.vue';

type SettingsState = {
    password: boolean;
    email: boolean;
    phone: boolean;
    securityQuestion: boolean;
    twoFactorAuth: boolean;
    newEmail: boolean;
    newPhone: boolean;
};

type SettingType = keyof SettingsState;

type Error = {
    response: {
        data: {
            errors: string[];
        }
    }
}

export default {
    components: {
        SecurityQuestionSelect,
        TextInput,
        MessageCard,
        Alerts
    },
    setup() {
        const openModal = useModalStore().openModal;
        const securityQuestions: Ref<SecurityQuestion[]> = ref([]);
        const securityQuestionsArray: Ref<Array<{ key: number, value: string }>> = ref([]);
        const userQuestions: Ref<SecurityQuestion[]> = ref([]);
        const showPassword = ref(false);
        const isSQEditable = ref(false);
        const showPasswordConfirmation = ref(false);
        const showAddNewQuestion = ref(false);
        const currentPassword = ref('');
        const password = ref('');
        const passwordConfirmation = ref('');
        const email = ref('');
        const phone = ref('');
        const newEmail = ref('');
        const newPhone = ref('');
        const emailConfirmation = ref('');
        const emailReadOnly = ref(true);
        const phoneNumber = ref('');
        const errors = ref<string[]>([]);
        const success = ref('');
        const isEmployer = useAuthStore().isEmployer;
        const isLocal = useAuthStore().isLocal;
        const userEmail = ref('');
        const userPhone = ref('');
        const numberOfQuestions = isEmployer || isLocal ? 3 : 1;
        const selectedQuestions: Ref<SelectedQuestionType[]> = ref([]);
        const loading = ref(true);
        const resetInputs = ref(false);
        const preferredMethod = ref('');
        const updateSettingsState = ref<SettingsState>({
            password: false,
            email: false,
            phone: false,
            securityQuestion: false,
            twoFactorAuth: false,
            newEmail: false,
            newPhone: false
        });

        const loadingUpdate = ref<SettingsState>({
            password: false,
            email: false,
            phone: false,
            securityQuestion: false,
            twoFactorAuth: false,
            newEmail: false,
            newPhone: false
        });

        // Toggle the visibility of editable fields
        const toggleUpdateSetting = (settingType: SettingType) => {
            updateSettingsState.value[settingType] = !updateSettingsState.value[settingType];
        };

        // Togle between hide/show password
        const togglePassword = () => {
            showPassword.value = !showPassword.value;
        }

        const togglePasswordConfirmation = () => {
            showPasswordConfirmation.value = !showPasswordConfirmation.value;
        }

        const selectedQuestionsId = ref<{ [key: string]: number }>({ select1: 0, select2: 0, select3: 0 });

        const availableQuestionsForSelect = (selectKey: string) => computed(() => {
            return securityQuestions.value?.filter(q => {
                return !Object.entries(selectedQuestionsId.value)
                    .filter(([key, value]) => key !== selectKey && value !== 0)
                    .map(([, value]) => value)
                    .includes(q.SecurityQuestionID as number);
            });
        })

        // Handle Security Questions Changes
        const handleQuestion = (payload: SelectedQuestion, index: number) => {
            selectedQuestionsId.value[`select${index}`] = payload.Question;
        }

        const addNewQuestion = () => {
            isSQEditable.value = !isSQEditable.value;
            showAddNewQuestion.value = !showAddNewQuestion.value;
        }

        // Get Security Questions
        const fetchData = async (isLoading: boolean) => {
            if (isLoading)
                loading.value = true;

            await axios.get('api/user/settings')
                .then(response => {
                    securityQuestions.value = response.data.SecurityQuestions;
                    securityQuestionsArray.value = Object.entries(response.data.SecurityQuestionsArray).map(([key, value]) => ({
                        key: Number(key),
                        value: String(value)
                    }));
                    userEmail.value = response.data.Email;
                    userPhone.value = response.data.Phone;
                    email.value = response.data.Email;
                    phone.value = response.data.Phone;
                    preferredMethod.value = response.data.PreferredMethod;

                    if (response.data.UserSecurityQuestions.length > 0) {
                        response.data.UserSecurityQuestions.forEach((element: SecurityQuestion, index: number) => {
                            selectedQuestionsId.value[`select${index + 1}`] = element.SecurityQuestionID as number;
                        });
                    }

                    userQuestions.value = response.data.UserSecurityQuestions;
                    userQuestions.value.map((question: SecurityQuestion) => {
                        const questionObj: SelectedQuestionType = {
                            Answer: "",
                            ExistingQuestion: question.ID,
                            Question: question.SecurityQuestionID as number,
                        }

                        selectedQuestions.value?.push(questionObj);
                    });
                })
                .finally(() => {
                    if (isLoading)
                        loading.value = false;
                });
        };

        // Verify current password
        const verifyPassword = async () => {
            try {
                const verifyResponse = await axios.post('/api/user/verify-password', {
                    current_password: currentPassword.value,
                });

                if (verifyResponse.data.success) {
                    toggleUpdateSetting('password');
                }
            } catch (error: any) {
                if (error.response) {
                    errors.value = Object.values(error.response.data.errors).flat() as string[];
                }

                setTimeout(() => {
                    errors.value = [];
                }, 3500);
            }
        };

        // Submit Form
        const updateSettings = async (settingType: SettingType, formData: Record<string, any>) => {
            resetInputs.value = false;
            loadingUpdate.value[settingType] = true;

            axios.post('api/user/settings', { ...formData, _method: 'PATCH' })
                .then(response => {
                    success.value = response.data.success;

                    fetchData(false);

                    setTimeout(() => {
                        if (settingType === 'password') {
                            password.value = '';
                            passwordConfirmation.value = '';
                        }
                        else if (settingType === 'email') {
                            // emailConfirmation.value = '';
                            newEmail.value = '';
                        }
                        else if (settingType === 'phone') {
                            // emailConfirmation.value = '';
                            newPhone.value = '';
                        }
                        else if (settingType === 'securityQuestion') {
                            resetInputs.value = true;
                            selectedQuestions.value = [];
                        }
                    }, 1000);
                })
                .catch((error: any) => {
                    if (error.response) {
                        errors.value = Object.values(error.response.data.errors).flat() as string[];
                    }
                })
                .finally(() => {
                    setTimeout(() => {
                        errors.value = [];
                        success.value = '';
                    }, 3500);

                    updateSettingsState.value[settingType] = false;
                    loadingUpdate.value[settingType] = false;
                });
        };

        // Password form submit
        const updatePassword = () => {
            const formData = {
                password: password.value,
                password_confirmation: passwordConfirmation.value,
            };

            updateSettings('password', formData);
        };

        // Email form submit
        const updateEmail = () => {
            const formData = {
                email: email.value,
                newEmail: newEmail.value
                // email_confirmation: emailConfirmation.value,
            };

            updateSettings('email', formData);
        };

        const updatePhone = () => {
            const formData = {
                phone: phone.value,
                newPhone: newPhone.value
                // email_confirmation: emailConfirmation.value,
            };

            updateSettings('phone', formData);
        };

        // Security Question form submit
        const updateSecurityQuestion = () => {
            const formData = {
                security_questions: selectedQuestions.value,
            };

            updateSettings('securityQuestion', formData);
        };

        // Set 2 Factor Authentication
        const updatePreferredMethod = async (method: string) => {
            if (method !== preferredMethod.value) {
                let methodFullName = method == 'E' ? 'Email Address' : 'Mobile Phone';
                let title = 'Verify ' + methodFullName;

                openModal('enter-code-2fa', title, 'modal-success', null, null, undefined, undefined, {method: method} );
                // const response = await axios.post('/api/authorization/change/method', { method: preferredMethod.value })

                // if (response.data.success) {
                //     console.log('x');
                // }

            } else {
                // Display a message if the same method is selected
                alert('This is already your preferred method.');
            }
        };

        const addNewEmail = () => {
            updateSettingsState.value['newEmail'] = !updateSettingsState.value['newEmail'];
        }

        const addNewPhone = () => {
            updateSettingsState.value['newPhone'] = !updateSettingsState.value['newPhone'];
        }

        onMounted(async () => await fetchData(true));

        return {
            openModal,
            password,
            currentPassword,
            passwordConfirmation,
            newEmail,
            email,
            emailConfirmation,
            phone,
            userPhone,
            phoneNumber,
            preferredMethod,
            showPassword,
            showPasswordConfirmation,
            securityQuestions,
            securityQuestionsArray,
            userQuestions,
            numberOfQuestions,
            isEmployer,
            selectedQuestions,
            userEmail,
            emailReadOnly,
            errors,
            success,
            loading,
            loadingUpdate,
            resetInputs,
            updateSettingsState,
            verifyPassword,
            togglePassword,
            togglePasswordConfirmation,
            handleQuestion,
            updateSettings,
            updatePassword,
            updateEmail,
            updatePhone,
            updateSecurityQuestion,
            availableQuestionsForSelect,
            toggleUpdateSetting,
            addNewEmail,
            addNewPhone,
            updatePreferredMethod,
            showAddNewQuestion,
            addNewQuestion,
            isSQEditable,
        };
    },
}
</script>

<style>
.input-password {
    width: 90%;
}

.reset {
    border: none;
    outline: none;
    align-self: flex-start;
    background-color: transparent;
}

.input-password:focus,
.input-password:active {
    border: none;
    box-shadow: none;
}
</style>