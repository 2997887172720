<template>
  <div :class="['text-input', { 'error': error }, customClass]">
    <label class="body-text" :for="label">{{ label }}  <span v-if="isRequired" class="text-danger"> * </span></label>
    <div class="input-date-wrapper">
      <input ref="inputRef" :type="currentType" :id="label" :placeholder="placeholder"
        :class="['links', 'date-input', { 'has-icon': icon }]" :maxlength="maxlength" :max="maxDate"
        :value="inputValue" :readonly="readonly" @input="onInput" />

      <i v-if="icon" class="custom-date-icon iatse-icon-calendar" @click="focusInput"></i>
    </div>
    <small v-if="error?.length" class="text-secondary-red"> {{ error.join('. ') }} </small>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, watch, ref } from 'vue';

export default defineComponent({
  props: {
    label: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: null,
    },
    icon: {
      type: Boolean,
      default: true,
    },
    modelValue: {
    type: String,
    },
    error: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    customClass: {
      type: String,
      default: '',
    },
    inputType: {
      type: String,
      default: 'date'
    },
    futureAllow: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const currentType = computed(() => (props.readonly ? 'text' : props.inputType));
    const maxlength = computed(() => (props.placeholder ? (props.placeholder.length || 10) : 10));
    const inputRef = ref<HTMLInputElement | null>(null);
    const inputValue = ref(props.modelValue);

    const maxDate = computed(() => {
      return props.futureAllow ? '' : new Date().toISOString().split('T')[0];
    });

   // Format date in US timezone (America/New_York)
   const formatDateToUS = (date: string) => {
      const dateObj = new Date(date);
      const options: Intl.DateTimeFormatOptions = {
        timeZone: 'America/New_York',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      };

      return new Intl.DateTimeFormat('en-US', options).format(dateObj);
    };

    const pattern = computed(() => {
      if (props.placeholder === 'MM/YY') {
        return '^(0[1-9]|1[0-2])/\\d{2}$'
      }
      else if (props.placeholder === 'dd/mm/yyyy') {
        return '^(0[1-9]|[12][0-9]|3[01])/(0[1-9]|1[0-2])/\\d{4}$';
      }

      return '^(0[1-9]|1[0-9]|2[0-9]|3[0-1])/(0[1-9]|1[0-2])/\\d{4}$';
    });

    watch(() => props.modelValue, (newValue) => {
      inputValue.value = newValue;
    });

    const onInput = (event: Event) => {
      const target = event.target as HTMLInputElement;
      const value = target.value;

      const enteredDate = new Date(value);
      const currentDate = new Date();

      if (!props.futureAllow && enteredDate > currentDate) {
          const enteredParts = value.split('-'); // Assume ISO format (yyyy-mm-dd)
          const [year, month, day] = enteredParts.map(Number);

          // Adjust the year to the current year if it's causing the future issue
          const adjustedYear = currentDate.getFullYear();

          // Construct a fallback date
          const validDate = new Date(adjustedYear, (month || 1) - 1, day || 1); // Fallback to a safe date
          const formattedDate = `${validDate.getFullYear()}-${String(validDate.getMonth() + 1).padStart(2, '0')}-${String(validDate.getDate()).padStart(2, '0')}`;

          inputValue.value = formattedDate;
          emit('update:modelValue', formattedDate);
            return; // Prevent further action
        }

      inputValue.value = value;
      emit('update:modelValue', value);
    };

    const focusInput = () => {
      if (inputRef.value && !props.readonly) {
        const inputElement = inputRef.value as HTMLInputElement & { showPicker: () => void };

        inputElement.focus();
        inputElement.showPicker();
      }
    };

    return {
      inputRef,
      pattern,
      // formattedDate,
      maxlength,
      maxDate,
      inputValue,
      currentType,
      onInput,
      focusInput
    };
  },
});
</script>
